import { ToastPosition } from 'react-hot-toast';
import styled from 'styled-components';
import toast, { Toast } from 'react-hot-toast';

import errorIcon from '../../../assets/images/errorIcon.svg';
import infoIcon from '../../../assets/images/infoIcon.svg';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;

const BodyWraper = styled.div`
    width: 100%;
    padding-left: 21px;
`;

const BottomWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
`;

export enum SnackSize {
    SMALL = 1,
    LARGE = 2
}

export type ErrorSnackProps = {
    message: string | JSX.Element;
    type?: SnackType;
    position?: ToastPosition;
    size?: SnackSize;
};

export enum SnackType {
    error = 1,
    warning = 2,
    success = 3,
    info = 4
}

const getCollors = (
    type: SnackType
): {
    backgroundColor: string;
    borderColor: string;
    color: string;
} => {
    switch (type) {
        case SnackType.error:
            return {
                backgroundColor: '#F7EFEB',
                borderColor: '#EC2830',
                color: '#C75757'
            };
        case SnackType.warning:
            return {
                backgroundColor: '#FFF5E3',
                borderColor: '#FFB017',
                color: '#CF7C3F'
            };
        case SnackType.success:
            return {
                backgroundColor: '#EDF6ED',
                borderColor: '#3EA460',
                color: '#679877'
            };
        default:
            return {
                backgroundColor: '#E0F4FA',
                borderColor: '#47A5DA',
                color: '#5A98BA'
            };
    }
};

const getIcon = (type: SnackType) => {
    switch (type) {
        case SnackType.error:
            return errorIcon;
        case SnackType.warning:
            return warningIcon;
        case SnackType.success:
            return successIcon;
        default:
            return infoIcon;
    }
};

export const Snack = ({ message, type = SnackType.info, position, size = SnackSize.SMALL }: ErrorSnackProps) => {
    const colours = getCollors(type);
    const Wrapper = styled.div`
        color: ${colours.color};
        background-color: ${colours.backgroundColor};
        width: ${size === SnackSize.SMALL ? '360px' : '550px'};
        border: 1px solid ${colours.borderColor};
        padding: 18px 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-self: start;
    `;

    const Bottom = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 21px;
        background: ${colours.borderColor};
        color: #fff;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        font-size: 10px;
        text-transform: uppercase;
        cursor: pointer;
    `;

    const Header = styled.div`
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        height: 25px;
        color: ${colours.borderColor};
    `;

    const headerText = (type: SnackType) => {
        switch (type) {
            case SnackType.error:
                return 'Ошибка';
            case SnackType.warning:
                return 'Предупреждение';
            case SnackType.success:
                return 'Успех';
            default:
                return 'Информация';
        }
    };

    return toast.custom(
        (t: Toast) => (
            <Wrapper>
                <Icon src={getIcon(type)} />
                <BodyWraper>
                    <Header>{headerText(type)}</Header>
                    <div>{message}</div>
                    <BottomWrapper>
                        <Bottom onClick={() => toast.dismiss(t.id)}>Закрыть</Bottom>
                    </BottomWrapper>
                </BodyWraper>
            </Wrapper>
        ),
        { position: position || 'top-right', duration: 600000 }
    );
};
