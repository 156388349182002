import styled from 'styled-components';
import { TaskTableRow } from './TaskTableRow';
import { Link } from 'react-router-dom';
import { TaskListItemFragment } from '../../../graphql/api.types';
import { useCallback } from 'react';
import { useOnItemView } from '../../shared/hooks/useOnItemView';

const TableContainer = styled.div`
    width: 100%;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

type TasksTableProps = {
    tasks: Array<TaskListItemFragment>;
    loadMore: (skip: number) => void;
};

export const TasksTable = ({ tasks, loadMore }: TasksTableProps) => {
    const loadMoreCallback = useCallback(() => loadMore(tasks.length), [tasks.length, loadMore]);

    const [ref] = useOnItemView(loadMoreCallback);

    const renderRows = () => {
        const referredIndex = tasks.length - 5 > 0 ? tasks.length - 5 : tasks.length - 1;
        return tasks.map((t, i) => {
            const itemRef = i === referredIndex ? ref : undefined;
            return (
                <StyledLink to={`/tasks/${t.id}?source=tasks`} key={t.id}>
                    <div ref={itemRef}>
                        <TaskTableRow task={t} />
                    </div>
                </StyledLink>
            );
        });
    };
    return <TableContainer>{renderRows()}</TableContainer>;
};
