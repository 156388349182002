import React, { useContext, createContext, ReactNode, useState } from 'react';
import { SortOrder } from '../../../graphql/api.types';
import { DateRangeType } from '../../shared/components/Calendar/DateRangePicker';
import { DropdownItem } from '../../shared/components/Select';
import { TasksFiltersContextValueTypes } from '../../tasks/contexts/filters';

type onChangeParams = {
    name: string;
    value: string | DateRangeType | DropdownItem | null;
};

export type ClientsFiltersContextValueTypes = {
    orderBy: string;
    sortDirection: SortOrder;
    onChange: (newValue: onChangeParams | onChangeParams[]) => void;
    search: string;
    reset: () => void;
};

const INITIAL_VALUES: Omit<ClientsFiltersContextValueTypes, 'reset' | 'onChange' | 'queryParams'> = {
    orderBy: 'id',
    sortDirection: SortOrder.Asc,
    search: ''
};

const Context = createContext<ClientsFiltersContextValueTypes>({
    ...INITIAL_VALUES,
    onChange: () => null,
    reset: () => ({})
});

export const useClientsFiltersContext = () => useContext(Context);

export const ClientsFiltersContext = ({ children }: { children: ReactNode }) => {
    const [values, setValues] = useState(INITIAL_VALUES);

    const onChange: TasksFiltersContextValueTypes['onChange'] = newValue => {
        if (Array.isArray(newValue)) {
            const nextValues = { ...values };
            newValue.forEach(e => {
                //@ts-ignore
                nextValues[e.name] = e.value;
            });
            setValues(nextValues);
        } else {
            setValues({ ...values, [newValue.name]: newValue.value });
        }
    };
    const reset = () => {
        setValues(INITIAL_VALUES);
    };

    const contextValue = { ...values, onChange, reset };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
