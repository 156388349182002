import styled from 'styled-components';
import { TableRow } from '../../shared/components';
import { TaskTitle } from './TaskTitle';
import { Link } from 'react-router-dom';
import { ReactComponent as WiFiIcon } from 'assets/images/wifi.svg';

const TableCell = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
`;

const Table = styled.div`
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Row = styled(TableRow)`
    margin-bottom: 4px;
    position: relative;
`;

const Title = styled(TaskTitle)`
    margin-bottom: 14px;
    margin-left: 18px;
`;

const IconWrapper = styled.div<{ color?: string }>`
    position: absolute;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
`;

type row = {
    title: string;
    value: string | number | undefined | null;
};
type TaskTableProps = {
    title: string;
    rows: Array<row>;
    clientId?: string;
};

export const TaskTable = ({ title, rows, clientId }: TaskTableProps) => {
    const renderRows = () =>
        rows.map(row => (
            <Row background={'#F6F7F8'} template="1fr 1fr" key={row.title}>
                <TableCell>{row.title}</TableCell>
                {row.title === 'ФИО' ? (
                    <Link to={clientId ? `/clients/${clientId}` : ''}>
                        <TableCell>{row.value}</TableCell>
                    </Link>
                ) : (
                    <TableCell>{row.value}</TableCell>
                )}
                {row.title === 'Пробег' && row.value && (
                    <IconWrapper>
                        <WiFiIcon />
                    </IconWrapper>
                )}
            </Row>
        ));

    return (
        <Table>
            <Title>{title}</Title>
            {renderRows()}
        </Table>
    );
};
