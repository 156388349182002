import React, { useContext, createContext, ReactNode, useState } from 'react';

import { DateRangeType } from '../../shared/components/Calendar/DateRangePicker';
import { DropdownItem } from '../../shared/components/Select';
import { TasksQueryParams } from '../../../types/DTO';
import { useDebounce } from '../../shared/hooks';
import { SortOrder } from '../../../graphql/api.types';

type onChangeParams = {
    name: string;
    value: string | DateRangeType | DropdownItem | null;
};

export type TasksFiltersContextValueTypes = {
    orderBy: string;
    sortDirection: SortOrder;
    period: DateRangeType;
    search: string;
    manager: DropdownItem | null;
    status: DropdownItem | null;
    onChange: (newValue: onChangeParams | onChangeParams[]) => void;
    reset: () => void;
    queryParams: TasksQueryParams;
};

const INITIAL_VALUES: Omit<TasksFiltersContextValueTypes, 'reset' | 'onChange' | 'queryParams'> = {
    search: '',
    period: null,
    manager: null,
    status: null,
    orderBy: 'ID_COL',
    sortDirection: SortOrder.Asc
};
const Context = createContext<TasksFiltersContextValueTypes>({
    ...INITIAL_VALUES,
    queryParams: {},
    onChange: () => null,
    reset: () => ({})
});

export const useTasksFiltersContext = () => useContext(Context);

export const TasksFiltersContext = ({ children }: { children: ReactNode }) => {
    const [values, setValues] = useState(INITIAL_VALUES);

    const onChange: TasksFiltersContextValueTypes['onChange'] = newValue => {
        if (Array.isArray(newValue)) {
            const nextValues = { ...values };
            newValue.forEach(e => {
                //@ts-ignore
                nextValues[e.name] = e.value;
            });
            setValues(nextValues);
        } else {
            setValues({ ...values, [newValue.name]: newValue.value });
        }
    };
    const reset = () => {
        setValues(INITIAL_VALUES);
    };

    const debouncedSearch = useDebounce(values.search, 500);

    const queryParams = React.useMemo<TasksQueryParams>(() => {
        const { period, status, manager } = values;
        return {
            search: debouncedSearch,
            periodStart: (period && period[0].toISOString()) || undefined,
            periodEnd: (period && period[1].toISOString()) || undefined,
            statusId: status?.value || undefined,
            managerId: manager?.value,
            take: 100,
            skip: 0,
            orderBy:
                values.orderBy === 'ID_COL'
                    ? { [values.orderBy]: values.sortDirection }
                    : [
                          {
                              [values.orderBy]: values.sortDirection
                          },
                          { ID_COL: 'asc' }
                      ]
        };
    }, [debouncedSearch, values]);

    const contextValue = { ...values, onChange, reset, queryParams };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
