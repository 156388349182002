import React, { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnClickOutside = (ref: React.RefObject<any>, handler: (e: Event) => void) => {
    useEffect(() => {
        const listener = (event: Event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};
