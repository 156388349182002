import styled from 'styled-components';
import { Title } from 'modules/client/components/Title';

export const ModalTitle = styled(Title)`
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    padding: 0;
    color: #292527;
    margin-bottom: 15px;
`;
