import styled, { css } from 'styled-components';
import checked from 'assets/images/checked.svg';
import { borderMixin, inputMixin, itemContainerMixin, itemsContainerMixin } from 'modules/shared/components/Select';

export const selectorStyleMixin = css`
    cursor: pointer;
    .selector__placeholder,
    .selector__single-value {
        text-align: left;
    }

    .selector__menu-portal {
        z-index: 10;
    }

    .selector__control {
        ${borderMixin};
        cursor: pointer;
        flex-wrap: nowrap;
    }

    .selector__control--is-focused {
        ${borderMixin};
        box-shadow: none;
    }

    .selector__indicator-separator {
        display: none;
    }

    .selector__value-container {
        ${inputMixin};
        flex-wrap: nowrap;
        padding-top: 0;
        padding-bottom: 0;
    }

    .selector__indicator {
        svg {
            color: #93949f;
        }
    }

    .selector__indicator:hover {
        opacity: 0.7;
    }

    .selector__menu {
        ${itemsContainerMixin};
        box-shadow: none;

        .selector__menu-list {
            padding: 0;
        }

        .selector__option {
            ${itemContainerMixin};
            cursor: pointer;
        }

        .selector__menu-notice {
            ${itemContainerMixin};
        }
    }
`;

export const StyleWrapper = styled.div`
    width: 100%;

    .custom-selector {
        ${selectorStyleMixin}
    }
`;

export const ValueContainerStyles = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const OptionStyles = styled.div`
    display: flex;
    align-items: center;

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    input + span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }
    input + span::before {
        margin: 0 10px 0 0;
        height: 17px;
        width: 17px;
        content: '';
        display: inline-block;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #c5c6cb;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    input:checked + span::before {
        background-image: url(${checked});
        background-position: center;
        background-size: contain;
    }
`;
