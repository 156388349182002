import styled from 'styled-components';

export const CentredRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    &:last-child {
        margin-top: 50px;
    }
`;

export const ButtonsGroup = styled(CentredRow)`
    justify-content: flex-end;

    button:first-child {
        margin-right: 6px;
    }
`;
