import { EditRow, KeysOfClientFormTable } from 'modules/client/components/ConnectionsClientsTable/types';
import { ConnectionSelectorItem, Not, NotLinkTypes } from 'modules/client/components/ConnectionsClientsForm/types';
import { ComboboxOption } from 'modules/shared/components/inputs/Combobox/types';
import React, { useState } from 'react';
import {
    COLUMNS,
    editButtonRight,
    editButtonSize,
    editButtonTop,
    TABLE_TEMPLATE_ROWS
} from 'modules/client/components/ConnectionsClientsTable/constants';
import { ReactSelectOption } from 'types/data';
import {
    AbsoluteWrapper,
    ColumnTitle,
    HeaderRow,
    RowsWrapper,
    RowTitle,
    RowWrapper,
    TableButtonWrapper,
    TableRow
} from 'modules/client/components/ConnectionsClientsTable/styles';
import { Column, Row } from 'modules/shared/components/wrappers/grid/FlexWrapper';
import { RelativeWrapper } from 'modules/shared/components/wrappers/grid/RelativeWrapper';
import { Combobox } from 'modules/shared/components/inputs/Combobox';
import { Selector } from 'modules/shared/components/inputs/Selector';
import { TableButton } from 'modules/shared/components/buttons/TableButton';
import doneIcon from 'assets/images/done.svg';
import closeIcon from 'assets/images/close.svg';
import editIcon from 'assets/images/edit.svg';
import deleteIcon from 'assets/images/delete.svg';
import { useClientSelectorLogic } from 'modules/client/components/ConnectionsClientsForm';

interface ConnectionsClientsTableProps extends Not, NotLinkTypes {
    connectionData: ConnectionSelectorItem[];
    newConnectionData: ConnectionSelectorItem[];
    onDelete: (id: string) => void;
    onChange: (newValue: ComboboxOption | null) => void;
    onDone: (newValue: ConnectionSelectorItem[]) => void;
    hasError?: boolean;
    canEdit: boolean;
}

export const ConnectionsClientsTable = ({
    connectionData,
    newConnectionData,
    onDelete,
    onChange,
    onDone,
    not,
    notLinkTypes,
    hasError,
    canEdit
}: ConnectionsClientsTableProps) => {
    const rows = connectionData.map(({ client: { id, name, numberOfCars }, clientLinkType }) => ({
        id,
        name: name.split(' ').slice(0, 3).join(' '),
        linkType: clientLinkType.name,
        cars: numberOfCars,
        filler: '',
        gm: '-'
    }));

    const [editRow, setEditRow] = useState<null | EditRow>(null),
        editId = editRow?.id,
        editKey = editRow?.key;

    const isCombobox = COLUMNS.find(({ key }) => key === editKey)?.edit?.type === 'combobox';

    const originRowData = connectionData.find(({ client: { id } }) => id === editId);

    const [editedValue, setEditedValue] = useState<null | ReactSelectOption>(null);

    const { clientLinkTypes, cars, valuesOfClients, loadClientsFn, data, loadClients } = useClientSelectorLogic({
        not,
        notLinkTypes,
        value: editedValue
    });

    const canDelete = (id: string) => newConnectionData.some(({ client }) => client.id === id);

    const onDeleteItem = (id: string) => () => onDelete(id);

    const onEditRow =
        ({ id, key }: EditRow) =>
        () => {
            onRowCancel();
            setEditRow({ id, key });
        };

    const onRowChange = (value: ReactSelectOption | null) => {
        setEditedValue(value);

        if (isCombobox) {
            onChange(value);
            loadClients({ variables: { search: value?.label || '' } });
        }
    };

    const onRowCancel = () => {
        onChange(null);
        setEditRow(null);
        setEditedValue(null);
        loadClients();
    };

    const onRowSave = () => {
        let newData = {};

        if (editKey === 'name') {
            const newClient = data?.allClients.find(({ id }) => id === editedValue?.value);

            if (newClient) {
                newData = {
                    client: {
                        id: newClient.id,
                        name: newClient.CLIENT || '',
                        numberOfCars: cars?.length
                    }
                };
            }
        }

        if (editKey === 'linkType') {
            newData = { clientLinkType: { id: editedValue?.value, name: editedValue?.label } };
        }

        const newConnectionData: ConnectionSelectorItem[] = connectionData.map(data => {
            if (data.client.id === editId) {
                return {
                    ...data,
                    ...newData
                };
            }

            return data;
        });

        onDone(newConnectionData);
        onRowCancel();
    };

    const isDisabled = (key: KeysOfClientFormTable) => {
        if (hasError || !editedValue) {
            return true;
        }

        if (key === 'name') {
            return !(cars && editedValue.value !== originRowData?.client?.id);
        }
        if (key === 'linkType') {
            return editedValue.value === originRowData?.clientLinkType?.id;
        }
    };

    return (
        <>
            <HeaderRow background="#EAEBEE" template={TABLE_TEMPLATE_ROWS} marginBottom="10px">
                {COLUMNS.map(({ key, value }) => (
                    <Row justifyCenter key={key}>
                        <ColumnTitle>{value}</ColumnTitle>
                    </Row>
                ))}
            </HeaderRow>
            <RowsWrapper>
                {rows.map(({ id, ...rowsValues }) => (
                    <TableRow
                        key={id}
                        position="relative"
                        background="#F6F7F8"
                        template={TABLE_TEMPLATE_ROWS}
                        marginBottom="5px"
                    >
                        {COLUMNS.map(({ key, edit }) => (
                            <Row justifyCenter alignCenter noWrap key={key}>
                                {editId === id && editKey === key ? (
                                    <RelativeWrapper width="90%">
                                        {isCombobox ? (
                                            <Combobox
                                                defaultOptions={valuesOfClients}
                                                placeholder={rowsValues[key] as string}
                                                loadFn={loadClientsFn}
                                                onChange={onRowChange}
                                                withPortal
                                                value={editedValue}
                                            />
                                        ) : (
                                            <Selector
                                                withPortal
                                                placeholder={rowsValues[key] as string}
                                                options={clientLinkTypes?.map(({ id, name }) => ({
                                                    label: name,
                                                    value: id
                                                }))}
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                onChange={value => onRowChange(value as ReactSelectOption)}
                                                value={editedValue}
                                            />
                                        )}
                                        <AbsoluteWrapper top="5px" right="-60px">
                                            <Row alignCenter justifyCenter>
                                                <TableButton
                                                    src={doneIcon}
                                                    size={editButtonSize}
                                                    type="button"
                                                    alt="edit"
                                                    disabled={isDisabled(key)}
                                                    onClick={onRowSave}
                                                />
                                                <TableButton
                                                    src={closeIcon}
                                                    size={editButtonSize}
                                                    type="button"
                                                    alt="edit"
                                                    onClick={onRowCancel}
                                                />
                                            </Row>
                                        </AbsoluteWrapper>
                                    </RelativeWrapper>
                                ) : (
                                    <RowWrapper>
                                        <RowTitle>{rowsValues[key]}</RowTitle>
                                        {edit && canEdit && (
                                            <TableButtonWrapper isClosed top={editButtonTop} right={editButtonRight}>
                                                <TableButton
                                                    src={editIcon}
                                                    size={editButtonSize}
                                                    type="button"
                                                    alt="edit"
                                                    onClick={onEditRow({ id, key })}
                                                />
                                            </TableButtonWrapper>
                                        )}
                                    </RowWrapper>
                                )}
                            </Row>
                        ))}
                        <AbsoluteWrapper isClosed height="100%" right="10px" top="0">
                            <Column height="100%" alignCenter justifyCenter>
                                {(canEdit || canDelete(id)) && (
                                    <TableButton
                                        src={deleteIcon}
                                        type="button"
                                        alt="delete"
                                        onClick={onDeleteItem(id)}
                                    />
                                )}
                            </Column>
                        </AbsoluteWrapper>
                    </TableRow>
                ))}
            </RowsWrapper>
        </>
    );
};
