import Calendar from 'react-calendar';
import React, { useState } from 'react';
import styled from 'styled-components';

import './calendar.css';
import selectPolygonSrc from '../../../../assets/images/selectPolygon.svg';
import { Button } from '../Button';
import { Modal } from '../Modal';
import moment from 'moment';
import { Sizes } from 'types/styles';
import { borderMixin } from 'modules/shared/components/Select';

const SelectPolygon = styled.img.attrs({ src: selectPolygonSrc })`
    width: 12px;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 12px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    cursor: pointer;
    border-radius: inherit;
`;

const Placeholder = styled.div`
    color: #93949f;
`;

const CalendarContainer = styled.div`
    width: 328px;
    height: 447px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

const CalendarButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;
const CalendarButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const CancelButton = styled(Button)`
    margin-right: 6px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const UIWrapper = styled.div<Sizes>`
    width: ${({ width }) => width || '100px'};
    height: ${({ height }) => height || '40px'};
    background-color: #fff;
    ${borderMixin};
    margin-right: 20px;
`;

const Label = styled.label`
    position: absolute;
    left: 14px;
    background: white;
    top: -5px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #93949f;
`;

export const formatDateRangeToString = (value: DateRangeType | null) =>
    value ? value.map(v => moment(v).format('DD.MM.yyyy')).join(' - ') : '';

export type DateRangeType = null | Array<Date>;

type DateRangePickerProps = {
    value: DateRangeType;
    onChange: (value: DateRangeType) => void;
    label?: string;
    placeholder?: string;
};

export const DateRangePicker = ({ onChange, value, label, placeholder }: DateRangePickerProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [tempValue, setTempValue] = useState<DateRangeType>(null);
    const renderTitle = () => {
        if (value) {
            const formattedValue = formatDateRangeToString(value);
            return <div>{formattedValue}</div>;
        }
        return <Placeholder>{placeholder || 'Все периоды'}</Placeholder>;
    };

    const renderCalendar = () => {
        const onCancel = () => {
            setTempValue(null);
            setIsOpen(false);
        };

        const onSubmit = () => {
            onChange(tempValue);
            setTempValue(null);
            setIsOpen(false);
        };

        const onReset = () => {
            const firstDate = moment()
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0
                })
                .toDate();
            const lastDate = moment()
                .set({
                    hour: 23,
                    minute: 59,
                    second: 59
                })
                .toDate();
            onChange([firstDate, lastDate]);
            setTempValue(null);
            setIsOpen(false);
        };

        const calendarValue = tempValue || value;
        return (
            <Modal isOpen={isOpen}>
                <CalendarContainer>
                    <Calendar selectRange={true} value={calendarValue} onChange={setTempValue} />
                    <CalendarButtonGroup>
                        <CancelButton theme="secondary" onClick={onReset}>
                            Сегодня
                        </CancelButton>
                        <CalendarButtonsContainer>
                            <CancelButton theme="secondary" onClick={onCancel}>
                                Отмена
                            </CancelButton>
                            <Button onClick={onSubmit}>Готово</Button>
                        </CalendarButtonsContainer>
                    </CalendarButtonGroup>
                </CalendarContainer>
            </Modal>
        );
    };

    return (
        <Wrapper>
            {label && value && <Label>{label}</Label>}
            <Container onClick={() => setIsOpen(true)}>
                {renderTitle()}
                <SelectPolygon />
            </Container>
            {renderCalendar()}
        </Wrapper>
    );
};

interface UIDateRangePickerProps extends DateRangePickerProps, Sizes {}

export const UIDateRangePicker = ({ width, height, ...props }: UIDateRangePickerProps) => (
    <UIWrapper width={width} height={height}>
        <DateRangePicker {...props} />
    </UIWrapper>
);
