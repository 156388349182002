import { createGlobalStyle } from 'styled-components';
import { comboboxStyleMixin } from 'modules/shared/components/inputs/Combobox/styles';
import { selectorStyleMixin } from 'modules/shared/components/inputs/Selector/styles';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Gotham Pro', sans-serif;
  }

  body {
    margin: 0;
    background-color: #ffffff;
    overflow-x: auto;
    min-width: 1024px;
  }

  .main-wrapper {
    padding: 0 55px;
  }

  .tooltip-text {
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;

    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #ffffff;
    text-align: center;

    max-width: 250px;
    background: #93949f;
    border-radius: 6px;
    padding: 5px 10px;
    margin-left: -60px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #93949f transparent transparent transparent;
  }

  .tooltip-container {
    position: relative;
  }
  .tooltip-container:hover > .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 1200px) {
    .main-wrapper {
      padding: 0 20px;
    }
  }
  
  #portalRoot {
    ${comboboxStyleMixin}
    ${selectorStyleMixin}
  }


`;
