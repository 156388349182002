import styled from 'styled-components';
import { Margin, Position } from 'types/styles';
import { margin } from 'constants/styles/mixins';

interface TableRowProps extends Margin, Position {
    template?: string;
    background?: string;
}

export const TableRow = styled.div<TableRowProps>`
    width: 100%;
    padding: 10px 18px;
    display: grid;
    min-height: 36px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    color: #ffffff;
    background-color: ${({ background }) => (background ? background : '#93949F')};
    grid-template-columns: ${({ template }) => (template ? template : 'initial')};
    ${({ position }) => (position ? `position: ${position};` : '')};
    ${margin}
`;
