import { createContext, ReactNode, useContext } from 'react';

import { MeQuery, RoleName, useMeQuery } from 'graphql/api.types';
import { logout } from 'utils';

const hasRole = (roleName: RoleName, roles: MeQuery['me']['roles']) =>
    (roles || [])?.some(({ name }) => name === roleName);

export interface UserPermissions {
    permissions: {
        isManager: boolean;
        isDirector: boolean;
        isAdmin: boolean;
    };
}

type AppStateType = {
    user: MeQuery['me'] | null;
} & UserPermissions;

const AppState = createContext<AppStateType>({
    user: null,
    permissions: { isManager: false, isAdmin: false, isDirector: false }
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const token = localStorage.getItem('token');

    const { data, loading } = useMeQuery({ skip: !token, fetchPolicy: 'no-cache' });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data?.me && token) {
        logout();
    }

    const user = data?.me || null,
        roles = user?.roles || [];
    const permissions: AppStateType['permissions'] = {
        isAdmin: hasRole(RoleName.Admin, roles),
        isDirector: hasRole(RoleName.Director, roles),
        isManager: hasRole(RoleName.Manager, roles)
    };

    return <AppState.Provider value={{ user: data?.me || null, permissions }}>{children}</AppState.Provider>;
};

export const useAppState = () => {
    const context = useContext(AppState);

    if (!context) {
        throw new Error('useAppState must be used within a AppProvider');
    }

    return context;
};
