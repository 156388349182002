import styled from 'styled-components';
import { Margin, ZIndex } from 'types/styles';
import { margin } from 'constants/styles/mixins';

interface Props extends Margin, ZIndex {}

export const MarginWrapper = styled.div<Props>`
    ${margin}
    ${({ zIndex }) => (zIndex !== undefined ? `z-index: ${zIndex};` : '')};
`;
