export enum TASK_STATUSES_ID {
    NEW = '27b8820d-2aa7-4403-9f18-c3ebc616c1b0',
    CANCELED = '9c84f71f-d6a7-41e4-b0c7-73602cd54f36',
    MOVED = 'b5979636-b6ab-4d9c-a8ed-c57990a4e823',
    COMPLETED = '7e23bc59-de6d-44ac-816d-20927432b4c0',
    IN_PROGRESS = '3271cb6a-6a4a-4adb-bfd7-ecf7ff5bdd98',
    IN_PROGRESS_DEPRECATED = 'ae547fca-c8e4-4f33-93d8-657fd75ac5fe',
    CLOSED = 'dca4a6c2-2c54-4b27-b722-fb9b529a663f'
}

export enum ERRORS {
    EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
    EMAIL_REGUIRED = 'EMAIL_REGUIRED',
    INTERNAL_SERVER_ERROR = 'Произошла ошибка',
    INVALID_PASSWORD = 'Неправильный логин или пароль',
    NOT_ADMIN = 'NOT_ADMIN',
    NOT_MANAGER = 'NOT_MANAGER',
    NOT_DIRECTOR = 'NOT_DIRECTOR',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    NOT_VALID_EMAIL = 'NOT_VALID_EMAIL',
    PASSWORD_REGUIRED = 'Введите пароль',
    USER_NOT_FOUND = 'Неправильный логин или пароль',
    DEFAULT = 'Произошла ошибка',
    VALIDATION_FAILED = 'Неверные данные формы (неверная длинна, или пустое значение)'
}
