import styled from 'styled-components';
import { HeaderTabs } from './HeaderTabs';
import { Logo } from './Logo';
import { HeaderLogout } from './HeaderLogout';

const Wrapper = styled.div`
    padding-top: 25px;
    padding-left: 77px;
    padding-right: 55px;
    background-color: #f6f7f8;
    display: flex;
    justify-content: space-between;
`;

const RightPart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-start;
`;

const LeftPart = styled.div`
    padding-top: 32px;
`;

const LogoWrapper = styled.div`
    height: 33px;
    margin-bottom: 20px;
`;

export const Header = () => {
    return (
        <Wrapper>
            <LeftPart>
                <HeaderTabs />
            </LeftPart>
            <RightPart>
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                <HeaderLogout />
            </RightPart>
        </Wrapper>
    );
};
