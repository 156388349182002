import styled from 'styled-components';
import { TaskTitle } from './TaskTitle';

const Title = styled(TaskTitle)`
    margin-bottom: 15px;
    margin-left: 18px;
`;

const CommentPlace = styled.div`
    padding: 25px 28px;
    background: #f6f7f8;
    width: 100%;
    min-height: 272px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527; ;
`;

export const PMComment = ({ comment }: { comment: string }) => {
    return (
        <div>
            <Title>Комментарии ПМ</Title>
            <CommentPlace>{comment}</CommentPlace>
        </div>
    );
};
