import { Column } from 'modules/client/components/ConnectionsClientsTable/types';

export const TABLE_TEMPLATE_ROWS = '2.5fr 1fr 3.5fr 2fr 2fr';

export const COLUMNS: Array<Column> = [
    {
        key: 'linkType',
        value: 'Связь',
        edit: {
            type: 'selector'
        }
    },
    {
        key: 'filler',
        value: ''
    },
    {
        key: 'name',
        value: 'Имя',
        edit: {
            type: 'combobox'
        }
    },
    {
        key: 'cars',
        value: 'Автомобиль'
    },
    {
        key: 'gm',
        value: 'GM1'
    }
];

export const editButtonSize = '30px';
export const editButtonMargin = '5px';
export const editButtonTop = `calc(50% - ${parseInt(editButtonSize) / 2}px)`;
export const editButtonRight = `calc(-${editButtonSize} - ${editButtonMargin})`;
