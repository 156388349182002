import { Fragment } from 'react';
import styled from 'styled-components';
import iconSrc from '../../../assets/images/breadcrumbs-separator.svg';
import { Link } from 'react-router-dom';

type Breadcrumb = { link?: string; title: string };
type BreadcrumbsProps = {
    crumbs: Array<Breadcrumb>;
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 28px;
`;

const Separator = styled.img.attrs({ src: iconSrc })`
    margin: 0 20px;
    width: 7px;
`;

const LinkItem = styled(Link)`
    text-decoration: none;
    color: #93949f;
`;

const Item = styled.div`
    color: #292527;
`;

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
    const renderCrumb = ({ title, link }: Breadcrumb) => {
        if (link) return <LinkItem to={link}>{title}</LinkItem>;
        return <Item>{title}</Item>;
    };

    const renderCrumbs = () =>
        crumbs.map((crumb, i) => {
            const firstItem = i === 0;
            if (firstItem) {
                return <Fragment key={i}>{renderCrumb(crumb)}</Fragment>;
            }
            return (
                <Fragment key={i}>
                    <Separator />
                    {renderCrumb(crumb)}
                </Fragment>
            );
        });

    return <Wrapper>{renderCrumbs()}</Wrapper>;
};
