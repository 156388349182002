import { Button, ButtonProps } from './styles';

interface TableButtonProps extends ButtonProps {
    src: string;
    alt: string;
}

export const TableButton = ({ src, alt, ...props }: TableButtonProps) => (
    <Button {...props}>
        <img src={src} alt={alt} />
    </Button>
);
