import { ReactNode } from 'react';
import styled from 'styled-components';
import { useNonScrolledBackground } from 'modules/shared/hooks/useNonScrolledBackground';
import { IsOpen } from 'types/data';

export const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

export interface ModalProps extends IsOpen {
    children: ReactNode;
    withoutNonScrolledBackground?: boolean;
}

export const Modal = ({ isOpen, withoutNonScrolledBackground = false, children }: ModalProps) => {
    useNonScrolledBackground(isOpen, withoutNonScrolledBackground);

    if (isOpen) return <Container>{children}</Container>;
    return null;
};
