import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useClientQuery, useClientsQuery } from 'graphql/api.types';
import { Breadcrumbs, Preloader } from '../shared/components';
import { generateClientBreadcrumbs } from './utils';
import { Cars, History, Personal } from './components';
import { useClientsFiltersContext } from '../clients/contexts/filters';
import iconSrc from '../../assets/images/breadcrumbs-separator.svg';

const Wrapper = styled.div`
    padding-top: 36px;
`;

const PreloaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
`;
const Container = styled.div`
    margin-top: 28px;
`;

const Table = styled.div`
    width: 48%;
`;

const TablesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NavButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
`;
const NavButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #f6f7f8;
    &:first-child {
        margin-right: 20px;
        transform: rotate(180deg);
    }
    &:disabled {
        background-color: #a5a5a5;
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

const Triangle = styled.img.attrs({ src: iconSrc })`
    width: 10px;
`;

export const Client = () => {
    const { id } = useParams<{ id: string }>();
    const { search: location } = useLocation();

    const source = useMemo(() => {
        return new URLSearchParams(location).get('source') || null;
    }, [location]);

    const { search, sortDirection, orderBy } = useClientsFiltersContext();

    const queryVariables = useMemo(
        () => ({
            orderBy: { [orderBy]: sortDirection },
            search: search,
            take: 100
        }),
        [orderBy, sortDirection, search]
    );

    const clientsQuery = useClientsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-only',
        refetchWritePolicy: 'overwrite',
        variables: { ...queryVariables, skip: 0 }
    });

    const clientIds = useMemo(() => {
        if (source === 'clients') {
            return clientsQuery.data?.clients.map(t => t.id);
        }
        return null;
    }, [source, clientsQuery]);

    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const getPrevAndNextIds = () => {
        if (clientIds?.length) {
            const currentIndex = clientIds.indexOf(params.id || '');
            if (clientIds) {
                const prev = currentIndex > 0 ? clientIds[currentIndex - 1] : null;
                const next = currentIndex < clientIds?.length - 1 ? clientIds[currentIndex + 1] : null;
                return { prev, next };
            }
        }
        return { prev: null, next: null };
    };
    const { next, prev } = getPrevAndNextIds();
    const { loading, data, refetch } = useClientQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            id: id
        }
    });

    const renderPreloader = () => {
        if (loading) {
            return (
                <PreloaderContainer>
                    <Preloader />
                </PreloaderContainer>
            );
        }
    };

    const renderContent = () => {
        if (!data?.client || loading) return null;

        const { client } = data;
        const cars = client.cars;
        const crumbs = generateClientBreadcrumbs(client.CLIENT || '');

        return (
            <>
                <HeaderContainer>
                    <Breadcrumbs crumbs={crumbs} />
                    <NavButtonContainer>
                        <NavButton disabled={!prev} onClick={() => navigate(`/clients/${prev}?source=${source}`)}>
                            <Triangle />
                        </NavButton>
                        <NavButton disabled={!next} onClick={() => navigate(`/clients/${next}?source=${source}`)}>
                            <Triangle />
                        </NavButton>
                    </NavButtonContainer>
                </HeaderContainer>
                <Container>
                    <TablesContainer>
                        <Table>
                            <Personal onUpdate={refetch} client={client} />
                        </Table>
                        <Table>
                            <Cars client={client} onUpdate={refetch} />
                        </Table>
                    </TablesContainer>
                    <History clientId={id} cars={cars} />
                </Container>
            </>
        );
    };

    return (
        <Wrapper>
            {renderPreloader()}
            {renderContent()}
        </Wrapper>
    );
};
