import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TaskTable } from './components/TaskTable';
import { ChangesHistory, onUpdateStatusParams } from './components/ChangesHistory';
import { PMComment } from './components/PMComment';
import { useCalendarTasksQuery, useTaskQuery, useTasksQuery, useTaskStatusUpdateMutation } from 'graphql/api.types';
import { Breadcrumbs, Snack, SnackType } from '../shared/components';
import { generateTaskBreadcrumbs, generateTaskTablesValues } from './utils';
import { useCalendarFiltersContext } from '../calendar/contexts/CalendarFiltersContext';
import { useTasksFiltersContext } from '../tasks/contexts/filters';
import iconSrc from '../../assets/images/breadcrumbs-separator.svg';

const Left = styled.div`
    max-width: 643px;
    width: 48%;
`;

const Right = styled.div`
    max-width: 639px;
    width: 48%;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 77px;
`;

const CommentWrapper = styled.div`
    margin-top: 47px;
`;

const Wrapper = styled.div`
    padding-top: 36px;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NavButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
`;
const NavButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #f6f7f8;
    &:first-child {
        margin-right: 20px;
        transform: rotate(180deg);
    }
    &:disabled {
        background-color: #a5a5a5;
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

const Triangle = styled.img.attrs({ src: iconSrc })`
    width: 10px;
`;

export const Task = () => {
    const params = useParams<{ id: string }>();
    const { search } = useLocation();

    const source = useMemo(() => {
        return new URLSearchParams(search).get('source') || null;
    }, [search]);

    const { managerId, selectedRange } = useCalendarFiltersContext();
    const { queryParams } = useTasksFiltersContext();

    const calendarTasksQuery = useCalendarTasksQuery({
        variables: {
            periodStart: selectedRange[0],
            periodEnd: selectedRange[1],
            managerId: managerId?.value
        },
        fetchPolicy: 'cache-only'
    });

    const tasksQuery = useTasksQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-only',
        refetchWritePolicy: 'overwrite',
        variables: { ...queryParams, skip: 0 }
    });

    const taskIds = useMemo(() => {
        if (!source) return null;
        if (source === 'calendar') {
            return calendarTasksQuery.data?.tasks.map(t => t.id);
        }
        if (source === 'tasks') {
            return tasksQuery.data?.tasks.map(t => t.id);
        }
    }, [source, tasksQuery, calendarTasksQuery]);
    const navigate = useNavigate();
    const [selectedStatusId, setSelectedStatusId] = useState<string | null>(null);
    const [statusHistory, setStatusHistory] = useState([]);

    const { refetch, data, error, loading } = useTaskQuery({ variables: { id: params.id } });

    const commentTemplates = useMemo(() => {
        return (
            (data?.task?.point.commentTemplates || []).map(el => ({
                title: el.commentTemplates.TEXT,
                value: el.commentTemplates.id
            })) || []
        );
    }, [data]);

    const [taskStatusUpdateMutation] = useTaskStatusUpdateMutation();

    const onUpdateStatus = async (param: onUpdateStatusParams) => {
        await taskStatusUpdateMutation({
            variables: {
                input: {
                    id: param.id,
                    LAST_COMMENT_CLIENT: param.comment,
                    statusId: param.statusId,
                    PLAN_DATE: param.date,
                    commentTemplateId: param.commentTemplateId
                }
            },
            onError: error => {
                Snack({ message: error.message, type: SnackType.warning });
            }
        });
        refetch();
    };

    const getComment = () => {
        if (statusHistory.length && selectedStatusId) {
            //@ts-ignore
            const status = statusHistory.find(i => i.id === selectedStatusId);
            //@ts-ignore
            return status?.LAST_COMMENT_CLIENT || '';
        }
        return '';
    };

    useEffect(() => {
        if (data?.task) {
            const { task } = data;

            const history = [
                ...task.statusHistory.map(status => ({
                    ...status,
                    LAST_COMMENT_CLIENT:
                        status.author?.login && status.LAST_COMMENT_CLIENT
                            ? `${status.author?.login}: ${status.LAST_COMMENT_CLIENT}`
                            : status.LAST_COMMENT_CLIENT
                }))
            ];

            //@ts-ignore
            setStatusHistory(history);
            setSelectedStatusId(task.status.id);
        }
    }, [data]);

    if (error) {
        navigate('/tasks', { replace: true });
        return null;
    }

    if (!loading && data?.task) {
        const task = data.task;
        const crumbs = generateTaskBreadcrumbs(`№${task.ID_COL} ${task.point.POINT_NAME}`);
        const taskTablesValues = generateTaskTablesValues(task);
        const clientId = task.client.id;
        if (!taskTablesValues) {
            return null;
        }

        const getPrevAndNextIds = () => {
            if (taskIds?.length) {
                const currentIndex = taskIds.indexOf(params.id || '');
                if (taskIds) {
                    const prev = currentIndex > 0 ? taskIds[currentIndex - 1] : null;
                    const next = currentIndex < taskIds?.length - 1 ? taskIds[currentIndex + 1] : null;
                    return { prev, next };
                }
            }
            return { prev: null, next: null };
        };
        const { next, prev } = getPrevAndNextIds();
        return (
            <Wrapper>
                <HeaderContainer>
                    <Breadcrumbs crumbs={crumbs} />
                    <NavButtonContainer>
                        <NavButton disabled={!prev} onClick={() => navigate(`/tasks/${prev}?source=${source}`)}>
                            <Triangle />
                        </NavButton>
                        <NavButton disabled={!next} onClick={() => navigate(`/tasks/${next}?source=${source}`)}>
                            <Triangle />
                        </NavButton>
                    </NavButtonContainer>
                </HeaderContainer>
                <Container>
                    <Left>
                        <TaskTable rows={taskTablesValues.info} title="Инфо" />
                        <TaskTable rows={taskTablesValues.car} title="Автомобиль" />
                        <TaskTable rows={taskTablesValues.client} clientId={clientId} title="Клиент" />
                    </Left>
                    <Right>
                        <ChangesHistory
                            onUpdateStatus={onUpdateStatus}
                            history={statusHistory}
                            planDate={task.CRM_PLAN_DATE}
                            factDate={task.CRM_FACT_DATE}
                            selectedStatusId={selectedStatusId}
                            onClick={setSelectedStatusId}
                            commentTemplates={commentTemplates}
                        />
                        <CommentWrapper>
                            <PMComment comment={getComment()} />
                        </CommentWrapper>
                    </Right>
                </Container>
            </Wrapper>
        );
    }

    return null;
};
