import styled, { css } from 'styled-components';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: string;
}

export const Button = styled.button<ButtonProps>`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    opacity: 0.3;
    :hover {
        opacity: 1;
    }

    :disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    ${({ size }) =>
        size &&
        css`
            width: ${size};
            height: ${size};
        `}
`;
