import styled from 'styled-components';
import { BorderProperties, SizesNumber } from 'types/styles';

//TODO: Refactoring Preloader
interface ContainerProps extends SizesNumber, Pick<BorderProperties, 'border'> {
    withoutMargin?: boolean;
}

const Container = styled.div<ContainerProps>`
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    display: inline-block;
    position: relative;
    width: ${({ width }) => (width ? `${width + width * 0.3}px` : '80px')};
    height: ${({ height }) => (height ? `${height + height * 0.3}px` : '80px')};

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${({ width }) => (width ? `${width}px` : '56px')};
        height: ${({ height }) => (height ? `${height}px` : '56px')};
        ${({ withoutMargin }) => (withoutMargin ? '' : 'margin: 6px;')};
        border: ${({ border }) => `${border || '6px'} solid`};
        border-color: #ec2830 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        animation: lds-ring 0.7s infinite linear;
    }
`;
type PreloaderProps = ContainerProps;

export const Preloader = (props: PreloaderProps) => {
    return (
        <Container {...props}>
            <div />
        </Container>
    );
};
