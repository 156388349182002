import styled from 'styled-components';

import { TaskQuery } from '../../../graphql/api.types';
import { CalendarColumnItem } from './CalendarColumnItem';

const Container = styled.div<{ active: boolean }>`
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${({ active }) => (active ? '1px solid #C5C6CB' : 'none')};
    margin-right: 14px;

    min-height: 0;
    min-width: 0;
`;

type DesktopColumnProps = {
    active: boolean;
    title: string;
    values: Array<TaskQuery['task']> | [];
};

const Title = styled.div<{ active: boolean }>`
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5px 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: ${({ active }) => (active ? '#FFFFFF' : '#93949F')};
    background-color: ${({ active }) => (active ? '#EC2830' : '#FFFFFF')};
    margin-bottom: 10px;
    white-space: pre-wrap;
`;

export const CalendarColumn = ({ active = false, title, values }: DesktopColumnProps) => {
    const renderTasks = () => values.map(task => <CalendarColumnItem key={task?.id} task={task} />);

    return (
        <Container active={!!values.length && active}>
            <Title active={active}>{title}</Title>
            {renderTasks()}
        </Container>
    );
};
