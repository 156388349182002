import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import { client } from 'configureApolloClient';
import App from './App';
import { AppProvider } from 'AppState';

const container = document.getElementById('root');

if (container) {
    const root = ReactDOMClient.createRoot(container);

    root.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <AppProvider>
                    <App />
                </AppProvider>
            </ApolloProvider>
        </React.StrictMode>
    );
}
