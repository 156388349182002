import { useRef, useCallback, useEffect } from 'react';

//Хак чтобы обойти баг с замыканиями https://dmitripavlutin.com/react-hooks-stale-closures/
let _shouldCall = true;
let _callback: (entries?: IntersectionObserverEntry[]) => void = () => ({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnItemView = (callback: (entries?: IntersectionObserverEntry[]) => any) => {
    const onObserve = (entries: IntersectionObserverEntry[]) => {
        if (!_shouldCall) return;
        const entry = entries[0];
        if (entry.isIntersecting) {
            _callback(entries);
            _shouldCall = true;
        }
    };

    const observer = new IntersectionObserver(onObserve, {
        root: null,
        threshold: 0.5
    });
    useEffect(() => {
        _callback = callback;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);

    const ref = useRef(null);

    const setRef = useCallback((node: HTMLDivElement | null) => {
        if (ref.current && node !== ref.current) {
            observer.unobserve(ref.current);
        }

        if (node && node !== ref.current) {
            _shouldCall = true;
            observer.observe(node);
        }

        //@ts-ignore
        ref.current = node;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [setRef];
};
