import { TableRow } from '../../shared/components';
import { TASK_TABLE_TEMPLATE_ROWS, COLUMNS } from '../constans';
import styled from 'styled-components';
import orderTriangleIconSrc from '../../../assets/images/orderTriangle.svg';
import { SortOrder } from '../../../graphql/api.types';

type TableCellProps = {
    content?: string;
    sortable?: boolean;
};

const StyledRow = styled(TableRow)`
    margin-bottom: 10px;
`;

const Cell = styled.div<TableCellProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ content }) => (content ? content : 'flex-start')};
    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
    padding-right: 10px;

    &:first-child {
        padding-left: 40px;
    }
`;

const OrderTriangle = styled.img.attrs({ src: orderTriangleIconSrc })<{ direction: SortOrder }>`
    width: 12px;
    margin-left: 6px;
    transform: ${({ direction }) => (direction === SortOrder.Desc ? 'initial' : 'rotate(180deg)')};
`;

type TaskTableRowProps = {
    onClickHeaderColumn: (v: string) => void;
    sortDirection: SortOrder;
    orderBy: string;
};

export const TasksTableHeaderRow = ({ onClickHeaderColumn, sortDirection, orderBy }: TaskTableRowProps) => {
    const renderCells = () =>
        COLUMNS.map(c => {
            const onClick = c.sortable ? () => onClickHeaderColumn(c.value) : undefined;
            return (
                <Cell onClick={onClick} sortable={c.sortable} content={c.content} key={c.key}>
                    {c.key}
                    {orderBy === c.value && <OrderTriangle direction={sortDirection} />}
                </Cell>
            );
        });
    return (
        <StyledRow background={'#93949F'} template={TASK_TABLE_TEMPLATE_ROWS}>
            {renderCells()}
        </StyledRow>
    );
};
