import styled from 'styled-components';
import { ButtonHTMLAttributes } from 'react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = styled.button<ButtonProps>`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }

    :disabled {
        cursor: not-allowed;
        opacity: 1;
    }
`;
