import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Title } from './Title';
import { ClientQueryResponse } from './index';
import { Button, Modal, Preloader, Snack, SnackType, TableRow } from '../../shared/components';
import polygonSrc from '../../../assets/images/selectPolygon.svg';
import moment from 'moment';
import {
    useClientLinksLazyQuery,
    useCreateManyClientLinkMutation,
    useDeleteManyClientLinkMutation
} from 'graphql/api.types';
import { ConnectionSelectorItem } from './ConnectionsClientsForm/types';
import { useAppState } from 'AppState';
import { ConnectionsClientsForm, ConnectionsFormValues } from './ConnectionsClientsForm';
import { CentredRow } from 'modules/shared/components/FormRows';
import { AbsoluteWrapper } from 'modules/shared/components/wrappers/grid/AbsoluteWrapper';
import { RelativeWrapper } from 'modules/shared/components/wrappers/grid/RelativeWrapper';
import { Row as FlexRow } from 'modules/shared/components/wrappers/grid/FlexWrapper';
import { timeout } from 'utils/timeout';
import { ApolloError } from '@apollo/client';
import { ModalTitle } from 'modules/shared/components/modals/styles';
import { defaultError } from 'constants/errorMessages';
import { ClientDescriptionForm } from 'modules/client/components/ClientDescriptionForm';
import { getLcr } from 'modules/clients/constans';

const Container = styled.div`
    width: 100%;
`;

const Row = styled(TableRow)`
    margin-bottom: 4px;
    grid-template-columns: 1fr 1fr;
    background: #f6f7f8;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Cell = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

type MaxHeightProps = {
    maxHeight?: number;
    maxWidth?: number;
};

const ModalContainer = styled.div<MaxHeightProps>`
    padding: 20px;
    width: 100%;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '510px')};
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px;` : '')};
    background-color: #ffffff;
`;

const TriangleIcon = styled.img.attrs({ src: polygonSrc })`
    width: 12px;
    transform: rotate(-90deg);
`;

type PersonalProps = { client: ClientQueryResponse; onUpdate: () => void };

export const Personal = ({ client, onUpdate }: PersonalProps) => {
    const [personModalOpen, setPersonModalOpen] = useState(false);
    const [connectionsModalOpen, setConnectionsModalOpen] = useState(false);

    const [loadClientLinks, { data: clientLinksData, loading: clientLinkLoading }] = useClientLinksLazyQuery({
        variables: { id: client!.id }
    });
    const clientLinks = clientLinksData?.clientLinks;

    const { permissions } = useAppState();

    const [createManyClientLinkMutation] = useCreateManyClientLinkMutation();
    const [deleteManyClientLinkMutation] = useDeleteManyClientLinkMutation();

    const updateClientLink = async ({ links }: { links: ConnectionSelectorItem[] }) => {
        let hasError = false;

        try {
            const deletedClientLinks = (clientLinks || [])?.filter(
                ({ link, linkType }) =>
                    !links.some(
                        ({ client: { id }, clientLinkType }) => link.id === id && clientLinkType.id === linkType.id
                    )
            );

            deletedClientLinks?.length &&
                (await deleteManyClientLinkMutation({ variables: { ids: deletedClientLinks.map(({ id }) => id) } }));

            const createdClientLink = links.filter(
                ({ client: { id }, clientLinkType }) =>
                    !clientLinks?.some(({ link, linkType }) => link.id === id && linkType.id === clientLinkType.id)
            );

            if (createdClientLink.length) {
                await createManyClientLinkMutation({
                    variables: {
                        data: createdClientLink.map(({ clientLinkType, client: link }) => ({
                            selfId: client?.id || '',
                            linkId: link.id,
                            linkTypeId: clientLinkType.id
                        }))
                    }
                });
            }
            await timeout(300);

            setConnectionsModalOpen(false);
        } catch (e) {
            hasError = true;

            const error = e as ApolloError,
                idMayBe = error.message.replace('Link already exists. id: ', '');

            let errorMessage: string | JSX.Element = defaultError;

            const { data } = await loadClientLinks();

            const existingClientLink = data?.clientLinks?.find(({ id }) => id === idMayBe);

            if (existingClientLink && existingClientLink.id) {
                errorMessage = (
                    <>
                        У клиента <b>{existingClientLink.self.CLIENT}</b> связь{' '}
                        <b>{existingClientLink.linkType.name}</b> с клиентом <b>{existingClientLink.link.CLIENT}</b> уже
                        существует
                    </>
                );
            }

            Snack({ message: errorMessage, type: SnackType.error });
        } finally {
            if (!hasError) {
                await loadClientLinks();
            }
        }
    };

    const connectionsFormValue: ConnectionsFormValues = useMemo(
        () => ({
            links: (clientLinks || []).map(({ link, linkType }) => ({
                clientLinkType: { name: linkType.name, id: linkType.id },
                client: { id: link.id, name: link.CLIENT || '', numberOfCars: link?.cars?.length || 0 }
            }))
        }),
        [clientLinks]
    );

    useEffect(() => {
        if (client?.id) {
            loadClientLinks({ variables: { id: client.id } });
        }
    }, [client, loadClientLinks]);

    //TODO: Refactor loader after refactor Preloader
    const Loader = () => (
        <FlexRow width="100%" height="100%" justifyCenter>
            <FlexRow width="15px" height="15px">
                <RelativeWrapper>
                    <AbsoluteWrapper top="0" left="0">
                        <Preloader withoutMargin border="2px" width={15} height={15} />
                    </AbsoluteWrapper>
                </RelativeWrapper>
            </FlexRow>
        </FlexRow>
    );

    if (!client) return null;

    return (
        <>
            <Container>
                <Title>Личные данные</Title>
                <div>
                    <Row>
                        <Cell>ID Клиента</Cell>
                        <Cell>{client.id}</Cell>
                    </Row>
                    <Row onClick={() => setPersonModalOpen(true)} style={{ cursor: 'pointer' }}>
                        <Cell>Контактная информация</Cell>
                        <Cell>
                            {client.CLIENT || client.PM_CLIENT_NAME} <TriangleIcon />
                        </Cell>
                    </Row>
                    <Row onClick={() => setConnectionsModalOpen(true)} style={{ cursor: 'pointer' }}>
                        <Cell>Связь клиента</Cell>
                        <Cell>
                            {clientLinkLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {clientLinks?.length || 'Отсутствует'}
                                    <TriangleIcon />
                                </>
                            )}
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>Персональный менеджер</Cell>
                        <Cell>{client.manager.login}</Cell>
                    </Row>
                    <Row>
                        <Cell>ЖЦК %</Cell>
                        <Cell>{getLcr(client.lcr)}</Cell>
                    </Row>
                    <ClientDescriptionForm
                        onUpdate={onUpdate}
                        clientId={client.id}
                        description={client.description || ''}
                    />
                </div>
            </Container>
            <Modal isOpen={personModalOpen}>
                <ModalContainer>
                    <CentredRow>
                        <ModalTitle>Клиент</ModalTitle>
                    </CentredRow>
                    <Row>
                        <Cell>ФИО</Cell>
                        <Cell>{client.CLIENT || client.PM_CLIENT_NAME}</Cell>
                    </Row>
                    <Row>
                        <Cell>Дата рождения</Cell>
                        <Cell>{client.PM_BIRTHDATE && moment(client.PM_BIRTHDATE).format('DD.MM.YYYY')}</Cell>
                    </Row>
                    <Row>
                        <Cell>Адрес</Cell>
                        <Cell>{client.PM_ADDRESS}</Cell>
                    </Row>
                    <Row>
                        <Cell>Телефон</Cell>
                        <Cell>{client.CLIENT_PHONE}</Cell>
                    </Row>
                    <Row>
                        <Cell>Электронная почта</Cell>
                        <Cell>{client.CLIENT_EMAIL}</Cell>
                    </Row>
                    <Row>
                        <Cell>Контактные лица</Cell>
                        <Cell>{client.PM_CLIENT_NAME}</Cell>
                    </Row>
                    <CentredRow>
                        <Button onClick={() => setPersonModalOpen(false)}>Закрыть</Button>
                    </CentredRow>
                </ModalContainer>
            </Modal>
            <Modal isOpen={connectionsModalOpen}>
                <ModalContainer maxWidth={900}>
                    <CentredRow>
                        <ModalTitle>{client.CLIENT || client.PM_CLIENT_NAME} имеет связь как ...</ModalTitle>
                    </CentredRow>

                    <ConnectionsClientsForm
                        values={connectionsFormValue}
                        permissions={permissions}
                        currentClient={{ id: client.id || '', name: client.CLIENT || '' }}
                        onClose={() => setConnectionsModalOpen(false)}
                        onSubmit={updateClientLink}
                    />
                </ModalContainer>
            </Modal>
        </>
    );
};
