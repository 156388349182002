import { Button } from 'modules/shared/components';
import React, { useState } from 'react';
import { ButtonWrapper, Comment } from './styles';
import { useFormik } from 'formik';
import { useUpdateClientDescriptionMutation } from 'graphql/api.types';

interface ClientDescriptionFormProps {
    onUpdate: () => void;
    clientId: string;
    description?: string;
}

export const ClientDescriptionForm = ({ description, clientId, onUpdate }: ClientDescriptionFormProps) => {
    const [showSaveDescription, setShowSaveDescription] = useState(false);
    const [mutation] = useUpdateClientDescriptionMutation();

    const formInitialValues = {
        description
    };

    const formData = useFormik({
        initialValues: formInitialValues,
        onSubmit: ({ description }) => {
            mutation({
                variables: {
                    description: description || '',
                    clientId
                }
            }).then(() => onUpdate());
        }
    });

    const onFocus = () => setShowSaveDescription(true);
    const onBlur = () => {
        if (description === formData.values.description) {
            setShowSaveDescription(false);
        }
    };

    return (
        <form onSubmit={formData.handleSubmit}>
            <Comment
                onFocus={onFocus}
                onBlur={onBlur}
                name="description"
                placeholder="Комментарий"
                value={formData.values.description}
                onChange={formData.handleChange}
            />
            {showSaveDescription && (
                <ButtonWrapper>
                    <Button type="submit">Сохранить</Button>
                </ButtonWrapper>
            )}
        </form>
    );
};
