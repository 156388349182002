import { ReportingOptions } from 'modules/shared/components/modals/ReportingModal/types';
// import { DropdownItem } from 'modules/shared/components/Select';
// import { SelectorOption } from 'modules/shared/components/inputs/Selector/types';

export const ColumnHeaderMarginBottom = '20px';

export const initialValues: ReportingOptions = {
    reportType: null,
    period: null,
    cities: [],
    dealerships: [],
    brands: [],
    personalManagers: [],
    taskStatuses: []
};

// export const reportTypes: DropdownItem[] = [
//     { title: 'ЖЦК (%)', value: '1' },
//     { title: 'Покрытие базы ПМ (%)', value: '2' },
//     { title: 'Воронка', value: '3' },
//     { title: 'Просроченные задачи', value: '4' }
// ];
//
// export const cities: SelectorOption[] = [
//     { label: 'Москва', value: '1' },
//     { label: 'Санкт-Петербург', value: '2' },
//     { label: 'Сочи', value: '3' },
//     { label: 'Омск', value: '4' },
//     { label: 'Тюмень', value: '5' },
//     { label: 'Краснодар', value: '6' }
// ];

export const reportingOptionsTitle: { [key in keyof ReportingOptions]: string } = {
    reportType: 'Тип отчета',
    period: 'Период',
    cities: 'Город',
    dealerships: 'Дилерский центр',
    brands: 'Бренд',
    personalManagers: 'Персональный менеджер',
    taskStatuses: 'Статус задачи'
};
