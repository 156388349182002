import React, { FC, useEffect, useState } from 'react';
import { Button } from 'modules/shared/components';
import { Column, FlexGrow, Row } from 'modules/shared/components/wrappers/grid/FlexWrapper';
import { DivWrapper } from 'modules/shared/components/wrappers/grid/DivWrapper';
import { MarginWrapper } from 'modules/shared/components/wrappers/grid/MarginWrapper';
import { ErrorText } from 'modules/client/components/ConnectionsClientsForm/styles';
import { DropdownItem, UISelect } from 'modules/shared/components/Select';
import { DoneButton } from 'modules/shared/components/buttons/DoneButton';
import { Combobox } from 'modules/shared/components/inputs/Combobox';
import { ComboboxLoadFn, ComboboxOption } from 'modules/shared/components/inputs/Combobox/types';
import { AllClientsQuery, useAllClientsLazyQuery, useClientLinkTypesLazyQuery } from 'graphql/api.types';
import { defaultGQLOptions } from 'constants/graphql';
import { useFormik } from 'formik';
import { ConnectionSelectorItem, Not, NotLinkTypes } from 'modules/client/components/ConnectionsClientsForm/types';
import { ConfirmationModal } from 'modules/shared/components/modals/ConfirmationModal';
import { ConfirmationModalState } from 'modules/shared/components/modals/ConfirmationModal/types';
import { initialModalState } from 'modules/shared/components/modals/ConfirmationModal/constants';
import { ConnectionsClientsTable } from 'modules/client/components/ConnectionsClientsTable';
import { differenceWith, isEqual } from 'lodash';
import { UserPermissions } from 'AppState';

interface ClientSelectorLogicOptions extends Not, NotLinkTypes {
    value: ComboboxOption | null;
}

export const useClientSelectorLogic = ({ not, notLinkTypes, value }: ClientSelectorLogicOptions) => {
    const [loadClients, { data }] = useAllClientsLazyQuery({
        ...defaultGQLOptions,
        variables: {
            skip: 0,
            take: 5,
            search: '',
            not
        }
    });
    const [loadClientLinkTypes, { data: clientLinkTypesData }] = useClientLinkTypesLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });
    const clientLinkTypes = clientLinkTypesData?.clientLinkTypes.filter(({ id }) => !notLinkTypes?.includes(id)) || [];

    const [cars, setCars] = useState<Array<{ __typename?: 'Car'; id: string; CAR_VIN: string }> | null>(null);

    const valuesOfClients = mapClientsToOptions(data);

    const loadClientsFn: ComboboxLoadFn = (search, cb) => {
        loadClients({ variables: { search, skip: 0, take: 5 } }).then(({ data }) => cb(mapClientsToOptions(data)));
    };

    useEffect(() => {
        loadClientLinkTypes();
        loadClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cars = data?.allClients?.find(({ id }) => id === value?.value)?.cars || null;
        value && setCars(cars);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, data]);

    return { clientLinkTypes, cars, valuesOfClients, loadClientsFn, data, loadClients };
};

const mapClientsToOptions = (data: AllClientsQuery | undefined) =>
    data
        ? data.allClients.map(({ CLIENT, id, PM_CLIENT_NAME }) => ({
              label: CLIENT || PM_CLIENT_NAME || '',
              value: id
          }))
        : [];

interface NewConnectionSelectorProps extends Not, NotLinkTypes {
    onDone: (newValue: ConnectionSelectorItem) => void;
    onChange: (newValue: ComboboxOption | null) => void;
    hasError?: boolean;
}

const NewConnectionSelector = ({ onDone, onChange, hasError, not, notLinkTypes }: NewConnectionSelectorProps) => {
    const [selectedClientValue, setSelectedClientValue] = useState<ComboboxOption | null>(null);
    const [clientLinkType, setClientLinkType] = useState<DropdownItem | null>(null);

    const { clientLinkTypes, cars, valuesOfClients, loadClientsFn, data, loadClients } = useClientSelectorLogic({
        not,
        notLinkTypes,
        value: selectedClientValue
    });

    const isDisableDoneButton = !(selectedClientValue && clientLinkType && cars && !hasError);

    const onClientsChange = (client: ComboboxOption | null) => {
        setSelectedClientValue(client);
        loadClients({ variables: { search: client?.label || '' } });
    };

    const onReset = () => {
        setSelectedClientValue(null);
        setClientLinkType(null);
        loadClients();
    };

    const onDoneClick = () => {
        const client = data?.allClients.find(({ id }) => id === selectedClientValue?.value);

        if (!isDisableDoneButton && client && clientLinkType) {
            onDone({
                client: {
                    id: client.id,
                    name: client.CLIENT || client.PM_CLIENT_NAME || '',
                    numberOfCars: cars?.length
                },
                clientLinkType: {
                    id: clientLinkType.value,
                    name: clientLinkType.title
                }
            });
            onReset();
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange(selectedClientValue), [selectedClientValue]);

    return (
        <Column width="100%">
            <Row width="100%" alignCenter>
                <FlexGrow marginRight="10px">
                    <Row width="100%">
                        <Row width="50%">
                            <UISelect
                                placeholder="Название связи"
                                width="100%"
                                items={clientLinkTypes?.map(({ id, name }) => ({ title: name, value: id }))}
                                selectedItem={clientLinkType}
                                onChange={value => setClientLinkType(value)}
                                withEmptyValue={false}
                            />
                        </Row>
                        <Row width="50%">
                            <Combobox
                                defaultOptions={valuesOfClients}
                                placeholder="Клиент"
                                loadFn={loadClientsFn}
                                onChange={onClientsChange}
                                value={selectedClientValue}
                            />
                        </Row>
                    </Row>
                </FlexGrow>
                <FlexGrow flexBasis="30px" flexGrow="0" alignCenter>
                    <DoneButton type="button" disabled={isDisableDoneButton} onClick={onDoneClick} />
                </FlexGrow>
            </Row>
        </Column>
    );
};

export interface ConnectionsFormValues {
    links: ConnectionSelectorItem[];
}

interface ConnectionsClientsFormProps extends UserPermissions {
    onClose: () => void;
    onSubmit: (values: ConnectionsFormValues) => void;
    currentClient: { id: string; name: string };
    values: ConnectionsFormValues;
}

export const ConnectionsClientsForm: FC<ConnectionsClientsFormProps> = ({
    onClose,
    currentClient,
    onSubmit,
    values,
    permissions
}) => {
    const formik = useFormik({
        initialValues: {
            links: values.links
        },
        onSubmit: async values => {
            await onSubmit(values);
        }
    });

    const [connectionData, setConnectionData] = useState<ConnectionSelectorItem[]>([]);
    const newConnectionData: ConnectionSelectorItem[] = differenceWith(connectionData, values.links, isEqual);
    const hasChanges = !!differenceWith(values.links, connectionData, isEqual).length || !!newConnectionData.length;

    const [notLinkType, setNotLinkType] = useState<string[]>([]);
    const [selectorValue, setSelectorValue] = useState<ComboboxOption | null>(null);
    const [deleteModalState, setDeleteModalState] = useState<ConfirmationModalState>(initialModalState);

    const [isSelectorActive, setIsSelectorActive] = useState(false);

    const onSelectorChange = (newValue: ConnectionSelectorItem) => {
        setConnectionData(value => [...value, newValue]);
    };

    const onConfirmDelete = (deletedId: string) => () => {
        setConnectionData(value => value.filter(({ client: { id } }) => id !== deletedId));
        setDeleteModalState(initialModalState);
    };

    const onCancelDelete = () => setDeleteModalState(initialModalState);

    const onDelete = (deletedId: string) => {
        const deletedClient = connectionData.find(({ client: { id } }) => id === deletedId),
            text = (
                <span>
                    Вы уверены что хотите удалить связь <b>{deletedClient?.clientLinkType.name}</b> клиента{' '}
                    <b style={{ whiteSpace: 'nowrap' }}>{currentClient.name}</b> с клиентом{' '}
                    <b style={{ whiteSpace: 'nowrap' }}>{deletedClient?.client.name}</b>?
                </span>
            );

        setDeleteModalState({
            onCancel: onCancelDelete,
            onConfirm: onConfirmDelete(deletedId),
            isOpen: true,
            title: 'Внимание',
            text
        });
    };

    const onCheck = (checkValue: ComboboxOption | null) => {
        const isExist = connectionData.some(({ client }) => client.id === checkValue?.value);

        formik.setErrors(isExist ? { links: `${checkValue?.label} уже добавлен(a)` } : {});
    };

    const setValuesFromClient = () => {
        setConnectionData(values.links);
    };

    const onRollback = () => {
        setValuesFromClient();
        formik.setErrors({});
    };

    const onAddOrRollbackClick = () => {
        setIsSelectorActive(value => !value);
        if (isSelectorActive) {
            onRollback();
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onCheck(selectorValue), [connectionData, selectorValue]);

    useEffect(() => {
        formik.setFieldValue('links', connectionData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionData]);

    useEffect(() => {
        const notLinkResult = [];
        const usedLinkTypes = connectionData.map(({ clientLinkType }) => clientLinkType),
            spousesLinkType = usedLinkTypes.find(({ name }) => name === 'Супруги'),
            parentLinkType = usedLinkTypes.filter(({ name }) => name === 'Родитель');

        spousesLinkType && notLinkResult.push(spousesLinkType.id);
        parentLinkType.length === 2 && notLinkResult.push(parentLinkType[0].id);

        setNotLinkType(notLinkResult);
    }, [connectionData]);

    useEffect(() => {
        setValuesFromClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const hasError = (formik.errors.links?.length || 0) > 0;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Row marginBottom="15px">
                <ConnectionsClientsTable
                    connectionData={connectionData}
                    newConnectionData={newConnectionData}
                    onDelete={onDelete}
                    onChange={setSelectorValue}
                    onDone={setConnectionData}
                    not={currentClient.id}
                    notLinkTypes={notLinkType}
                    hasError={hasError}
                    canEdit={permissions.isAdmin || permissions.isDirector}
                />
            </Row>
            <Row marginBottom="5px" alignCenter height="50px">
                {isSelectorActive && (
                    <NewConnectionSelector
                        hasError={hasError}
                        not={currentClient.id}
                        notLinkTypes={notLinkType}
                        onDone={onSelectorChange}
                        onChange={setSelectorValue}
                    />
                )}
            </Row>
            <Row marginBottom="50px" height="10px">
                {hasError && <ErrorText>{formik.errors.links as string | string[]}</ErrorText>}
            </Row>
            <Row justifyBetween>
                <Row>
                    <Button type="button" onClick={onAddOrRollbackClick} theme="secondary">
                        {isSelectorActive ? 'сбросить' : 'добавить связь'}
                    </Button>
                    {hasChanges && !isSelectorActive && (
                        <Row marginLeft="6px">
                            <Button type="button" onClick={onRollback} theme="secondary">
                                сбросить
                            </Button>
                        </Row>
                    )}
                </Row>
                <Row>
                    <MarginWrapper marginRight="6px">
                        <Button onClick={onClose} theme="secondary" type="reset">
                            отмена
                        </Button>
                    </MarginWrapper>
                    <DivWrapper>
                        <Button disabled={!hasChanges} type="submit">
                            Сохранить
                        </Button>
                    </DivWrapper>
                </Row>
            </Row>
            <ConfirmationModal
                isOpen={deleteModalState.isOpen}
                onConfirm={deleteModalState.onConfirm}
                onCancel={deleteModalState.onCancel}
                title={deleteModalState.title}
                text={deleteModalState.text}
            />
        </form>
    );
};
