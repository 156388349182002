import styled from 'styled-components';
import { TableRow as Row } from 'modules/shared/components';
import { AbsoluteWrapper as Wrapper } from 'modules/shared/components/wrappers/grid/AbsoluteWrapper';

export const ColumnTitle = styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
`;

export const RowTitle = styled(ColumnTitle)`
    font-weight: 400;
`;

export const RowsWrapper = styled.div`
    max-height: 400px;
    width: 100%;
    overflow: scroll;
`;

export const HeaderRow = styled(Row)``;

export const TableRow = styled(Row)`
    text-align: center;
    min-height: 58px;
`;

export const AbsoluteWrapper = styled(Wrapper)`
    ${TableRow}:hover & {
        display: block;
    }
`;

export const RowWrapper = styled.div`
    position: relative;
    white-space: nowrap;
`;

export const TableButtonWrapper = styled(Wrapper)`
    ${TableRow}:hover & {
        display: block;
    }
`;
