import styled from 'styled-components';
import { Column } from 'modules/shared/components/wrappers/grid/FlexWrapper';

export const ModalContainer = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 900px;
    min-width: 700px;
    background-color: #ffffff;
`;

export const ColumnHeader = styled.div`
    width: 100%;
    padding: 10px 15px;
    min-height: 36px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 120%;
    color: #ffffff;
    background-color: #93949f;
`;

export const InputWrapper = styled.div`
    width: 100%;
`;

export const InputsWrapper = styled(Column)`
    width: 100%;

    ${InputWrapper} {
        margin-bottom: 15px;
    }
`;

export const ReportOptionWrapper = styled(Column)`
    height: 40px;
`;

export const ReportOptionsWrapper = styled(Column)`
    width: 100%;

    ${ReportOptionWrapper} {
        margin-bottom: 15px;
    }
`;

export const ReportOptionTitle = styled.span`
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #93949f;
    margin-bottom: 5px;
`;

export const ReportOptionValue = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #292527;
`;
