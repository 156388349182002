import React, { createContext, ReactNode, useContext, useState } from 'react';
import { DropdownItem } from '../../shared/components/Select';
import { getDatesRangeInIsoWeekByDate } from '../utils';

type ContextType = {
    managerId: DropdownItem | null;
    selectedRange: Date[];
    setSelectedRange: (v: Date[]) => void;
    setManagerId: (v: DropdownItem | null) => void;
};

const Context = createContext<ContextType>({
    managerId: null,
    selectedRange: getDatesRangeInIsoWeekByDate(new Date()),
    setSelectedRange: () => ({}),
    setManagerId: () => ({})
});

export const CalendarFiltersContext = ({ children }: { children: ReactNode }) => {
    const defaultSelectedRange = getDatesRangeInIsoWeekByDate(new Date());

    const [selectedRange, setSelectedRange] = useState<Date[]>(defaultSelectedRange);

    const [managerId, setManagerId] = useState<DropdownItem | null>(null);
    return (
        <Context.Provider value={{ managerId, selectedRange, setSelectedRange, setManagerId }}>
            {children}
        </Context.Provider>
    );
};

export const useCalendarFiltersContext = () => useContext(Context);
