import { Modal } from 'modules/shared/components/Modal';
import { ModalContainer, ModalText, ModalTitle } from './styles';
import { ConfirmationModalState } from 'modules/shared/components/modals/ConfirmationModal/types';
import { Column, Row } from '../../wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'modules/shared/components/wrappers/grid/MarginWrapper';
import { Button } from 'modules/shared/components/Button';
import { DivWrapper } from 'modules/shared/components/wrappers/grid/DivWrapper';
import React from 'react';

type ConfirmationModalProps = ConfirmationModalState;

export const ConfirmationModal = ({ onConfirm, text, onCancel, title, isOpen }: ConfirmationModalProps) => {
    return (
        <Modal isOpen={isOpen} withoutNonScrolledBackground>
            <ModalContainer>
                <Column width="100%" height="100%">
                    {title && (
                        <Row marginBottom="20px" width="100%" justifyCenter alignCenter>
                            <ModalTitle>{title}</ModalTitle>
                        </Row>
                    )}
                    {text && (
                        <Row width="100%" marginBottom="20px" justifyCenter alignCenter>
                            <ModalText>{text}</ModalText>
                        </Row>
                    )}
                    <Row width="100%" justifyEnd>
                        <Row>
                            <MarginWrapper marginRight="6px">
                                <Button onClick={onConfirm} theme="secondary" type="button">
                                    Да
                                </Button>
                            </MarginWrapper>
                            <DivWrapper>
                                <Button type="button" onClick={onCancel}>
                                    Нет
                                </Button>
                            </DivWrapper>
                        </Row>
                    </Row>
                </Column>
            </ModalContainer>
        </Modal>
    );
};
