import styled from 'styled-components';

import { TasksFilters } from './components';
import { TasksTable } from './components/TasksTable';
import { SortOrder, useTasksQuery } from 'graphql/api.types';
import { Preloader } from '../shared/components';
import { useTasksFiltersContext } from './contexts/filters';
import React, { useState } from 'react';
import { TasksTableHeaderRow } from './components/TasksTableHeaderRow';
import { ReportingModal } from 'modules/shared/components/modals/ReportingModal';

const Wrapper = styled.div`
    padding-top: 36px;
`;

const PreloaderContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Tasks = () => {
    const [reportingModalOpen, setReportingModalOpen] = useState(false);

    const { queryParams, orderBy, sortDirection, onChange } = useTasksFiltersContext();

    const { loading, data, fetchMore } = useTasksQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite',
        variables: { ...queryParams, skip: 0 }
    });

    const tasks = data?.tasks || [];
    const loadMore = (skip: number) => {
        fetchMore({ variables: { ...queryParams, skip } });
    };

    const onClickHeaderColumn = (columnName: string) => {
        if (columnName === orderBy) {
            onChange({
                name: 'sortDirection',
                value: sortDirection === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
            });
        } else {
            onChange([
                { name: 'orderBy', value: columnName },
                { name: 'sortDirection', value: SortOrder.Asc }
            ]);
        }
    };

    return (
        <Wrapper>
            <TasksFilters onModalChange={setReportingModalOpen} />
            <TasksTableHeaderRow
                sortDirection={sortDirection}
                orderBy={orderBy}
                onClickHeaderColumn={onClickHeaderColumn}
            />
            <TasksTable tasks={tasks} loadMore={loadMore} />
            <ReportingModal isOpen={reportingModalOpen} onClose={() => setReportingModalOpen(false)} />
            {loading && (
                <PreloaderContainer>
                    <Preloader />
                </PreloaderContainer>
            )}
        </Wrapper>
    );
};
