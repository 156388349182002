import styled, { css } from 'styled-components';

interface ButtonProps {
    theme?: 'secondary' | 'primary';
    loading?: boolean;
    borderRadius?: string;
    height?: string;
}

export const Button = styled.button<ButtonProps>`
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px 10px;
    outline: none;
    border: none;
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
    ${({ height }) => height && `height: ${height};`}
    color: ${({ theme }) => (theme === 'secondary' ? '#93949F' : '#FFFFFF')};
    background: ${({ theme }) => (theme === 'secondary' ? '#EAEBEE' : '#EC2830')};
    cursor: pointer;
    transition: opacity 0.2s ease;
    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        cursor: not-allowed;
    }

    ${({ loading }) =>
        loading &&
        css`
            position: relative;
            color: transparent;
            @keyframes lds-ring {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
            :after {
                content: '';
                position: absolute;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                animation: lds-ring 0.7s infinite linear;
                border: 3px solid;
                border-color: #93949f rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
                top: 4px;
                z-index: 1;
            }
        `}
`;
