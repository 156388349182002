import styled from 'styled-components';
import moment from 'moment';

import { TableRow } from '../../shared/components';
import { getTaskRowStylesByStatus } from '../../../utils';
import { TASK_TABLE_TEMPLATE_ROWS } from '../constans';
import { TaskListItemFragment } from '../../../graphql/api.types';

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 4px;
    cursor: pointer;
`;

const StatusImage = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 20px;
`;

const StatusImagePlaceholder = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 20px;
`;

type CellProps = {
    color?: string;
    content?: string;
};

const Cell = styled.div<CellProps>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    padding-right: 10px;
    color: ${({ color }) => (color ? color : '#93949F')};
    justify-content: ${({ content }) => (content ? content : 'flex-start')};
`;

const renderIcon = (src: string | null) => {
    if (!src) return <StatusImagePlaceholder />;
    return <StatusImage src={src} />;
};

type TaskTableRowProps = {
    task: TaskListItemFragment;
};

export const TaskTableRow = ({ task }: TaskTableRowProps) => {
    // task.planDate
    const date = task.CRM_PLAN_DATE;

    const formattedDate = moment(date).isValid() ? moment(date).format('DD.MM.yyyy') : '';
    const formattedTime = formattedDate ? moment(date).format('HH:mm') : '';

    const car = task.car;
    const client = task.client;
    const status = task.status;

    const styles = getTaskRowStylesByStatus(status.id);

    return (
        <Wrapper>
            <TableRow template={TASK_TABLE_TEMPLATE_ROWS} background={styles.background}>
                <Cell color={styles.color}>
                    {renderIcon(styles.iconSrc)} {task.ID_COL}
                </Cell>
                <Cell color={styles.color}>{task.point.POINT_NAME}</Cell>
                <Cell content="center" color={styles.color}>
                    {formattedDate}
                </Cell>
                <Cell content="center" color={styles.color}>
                    {formattedTime}
                </Cell>
                <Cell color={styles.color}>{task.CLIENT_OR_PM_CLIENT_NAME}</Cell>
                <Cell color={styles.color}>{client.CLIENT_PHONE}</Cell>
                <Cell color={styles.color}>
                    {car.BRAND} {car.CAR_MODEL}
                </Cell>
                <Cell color={styles.color}>{styles.title}</Cell>
            </TableRow>
        </Wrapper>
    );
};
