import styled from 'styled-components';

export const Comment = styled.textarea`
    border: 1px solid #eaebee;
    min-width: 100%;
    max-width: 100%;
    min-height: 125px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 10px 14px;

    &::placeholder {
        color: #93949f;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;
`;
