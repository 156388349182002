import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TaskTitle } from './TaskTitle';
import { TableRow, Button } from '../../shared/components';
import { getTaskRowStylesByStatus } from '../../../utils';
import { CreateStatusModal } from './CreateStatusModal';
import moment from 'moment';
import { TASK_STATUSES_ID } from '../../shared/constans';
import { useAppState } from '../../../AppState';
import { DropdownItem } from 'modules/shared/components/Select';

const Container = styled.div`
    width: 100%;
`;

const Title = styled(TaskTitle)`
    margin-bottom: 14px;
    margin-left: 18px;
`;

const RowsContainersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const RowsContainer = styled.div`
    width: 48%;
`;

const TableCell = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    cursor: pointer;

    &:last-child {
        text-align: right;
    }
`;
const Row = styled(TableRow)<{ color?: string; selected?: boolean }>`
    margin-bottom: 4px;
    border: ${({ selected, color }) => (selected ? `1px solid ${color || '#292527'}` : 'none')};
    color: ${({ color }) => (color ? color : '#292527')};
`;

const StyledButton = styled(Button)`
    margin-top: 14px;
`;

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StatusIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 13px;
`;

const StatusIconPlaceholder = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 13px;
`;

export interface CommentTemplates {
    commentTemplates: DropdownItem[];
}

export type onUpdateStatusParams = {
    statusId: string;
    comment: string;
    id: string;
    date: Date | null;
    commentTemplateId?: string;
};

type ChangesHistoryProps = {
    history: Array<{
        COMMENT?: string | null;
        deletedAt: string;
        NAME: string;
        id: string | null;
        taskStatusId: string | null;
    }>;
    planDate: string | Date;
    factDate: string | Date;
    selectedStatusId: string | null;
    onClick: (id: string | null) => void;
    onUpdateStatus: (v: onUpdateStatusParams) => Promise<void>;
} & CommentTemplates;

export const ChangesHistory = ({
    history,
    planDate,
    factDate,
    selectedStatusId,
    onClick,
    onUpdateStatus,
    commentTemplates
}: ChangesHistoryProps) => {
    const { user } = useAppState();

    const isManager = useMemo(() => {
        if (user) {
            return (user.staff || []).length < 1;
        }
        return true;
    }, [user]);

    const [isOpen, setIsOpen] = React.useState(false);

    const renderStatusIcon = (src: string | null) => {
        if (src) return <StatusIcon src={src} />;
        return <StatusIconPlaceholder />;
    };

    const renderNewStatusButton = () => {
        const lastStatusId = history[0]?.taskStatusId;

        const commonFinalStatuses = [TASK_STATUSES_ID.CLOSED];
        const managerFinalStatuses = [TASK_STATUSES_ID.CANCELED, TASK_STATUSES_ID.COMPLETED];
        if (commonFinalStatuses.includes(lastStatusId as TASK_STATUSES_ID)) {
            return null;
        }
        if (managerFinalStatuses.includes(lastStatusId as TASK_STATUSES_ID) && isManager) {
            return null;
        }
        return <StyledButton onClick={() => setIsOpen(true)}>Новый статус</StyledButton>;
    };

    const renderStatuses = () =>
        history.map((h, i) => {
            const styles = getTaskRowStylesByStatus(h.taskStatusId || '');
            return (
                <Row
                    key={i}
                    background={styles.background}
                    template="1fr 1fr"
                    selected={h.id === selectedStatusId}
                    onClick={() => onClick(h.id)}
                    color={styles.color}
                >
                    <TableCell>
                        <StatusContainer>
                            {renderStatusIcon(styles.iconSrc)}
                            {styles.title}
                        </StatusContainer>
                    </TableCell>
                    <TableCell>{moment(new Date(h.deletedAt)).format('DD.MM.yyyy HH:mm')}</TableCell>
                </Row>
            );
        });

    return (
        <Container>
            <Title>История изменений</Title>
            <RowsContainersWrapper>
                <RowsContainer>
                    {renderStatuses()}
                    {renderNewStatusButton()}
                </RowsContainer>
                <RowsContainer>
                    <Row background={'#F6F7F8'} template="1fr 1fr">
                        <TableCell>Дата плана</TableCell>
                        <TableCell>{moment(planDate).format('DD.MM.yy')}</TableCell>
                    </Row>
                    <Row background={'#F6F7F8'} template="1fr 1fr">
                        <TableCell>Дата факта</TableCell>
                        <TableCell>{(factDate && moment(factDate).format('DD.MM.yy')) || ''}</TableCell>
                    </Row>
                </RowsContainer>
            </RowsContainersWrapper>
            <CreateStatusModal
                isManager={isManager}
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                onSubmit={onUpdateStatus}
                commentTemplates={commentTemplates}
            />
        </Container>
    );
};
