import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Auth } from '../modules/auth';

export const GuestRouter = () => (
    <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
);
