import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { get } from 'lodash';

import { TableRow } from '../../shared/components';
import settingsSrc from 'assets/images/settings.svg';
import { getLcr } from 'modules/clients/constans';

type CarProps = {
    car: {
        __typename?: 'Car';
        id: string;
        CAR_VIN: string;
        CAR_EQUIPMENT: string;
        CAR_MODEL: string;
        DATE_RELEASE: any;
        BRAND?: string | null;
        SALESMAN?: string | null;
        PM_VEHICLE_REGISTRATION_PLATE?: string | null;
        commentUserId?: string | null;
        lcr: number;
        tasks: Array<{
            __typename?: 'Task';
            LCR_PERC?: number | null;
            office: { __typename?: 'Office'; NAME_AX: string };
        }>;
        user?: { __typename?: 'User'; login: string; id: string } | null;
    };
    isManager: boolean;
    onOpenManagerModal: (value: boolean) => void;
};

const HeaderRow = styled(TableRow)`
    cursor: pointer;
    background: #eaebee;
    grid-template-columns: 2fr 3fr 1fr;
    margin-bottom: 4px;
`;

const Row = styled(HeaderRow)`
    cursor: initial;
    background-color: #f6f7f8;

    &:last-child {
        margin-bottom: 0;
    }
`;

interface CellProps {
    isEnd?: boolean;
}

const Cell = styled.div<CellProps>`
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    display: flex;
    ${({ isEnd }) => isEnd && 'justify-content: end;'};
`;

const Container = styled.div`
    width: 100%;
    padding-top: 11px;
`;

const SettingsIcon = styled.img.attrs({ src: settingsSrc })`
    width: 18px;
`;

const SettingsButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0 -3px 0 0;
    cursor: pointer;
`;

export const CarCard = ({ car, isManager, onOpenManagerModal }: CarProps) => {
    const officeName = get(car, 'tasks[0].office.NAME_AX', '');

    return (
        <Container>
            <Row>
                <Cell>VIN номер</Cell>
                <Cell>{car.CAR_VIN}</Cell>
            </Row>
            <Row>
                <Cell>Комплектация</Cell>
                <Cell>{car.CAR_EQUIPMENT}</Cell>
            </Row>
            <Row>
                <Cell>Дата продажи</Cell>
                <Cell>{car.DATE_RELEASE && moment(car.DATE_RELEASE).format('DD.MM.YYYY')}</Cell>
            </Row>
            <Row>
                <Cell>Госномер</Cell>
                <Cell>{car.PM_VEHICLE_REGISTRATION_PLATE || ''}</Cell>
            </Row>
            <Row>
                <Cell>Дилерский центр</Cell>
                <Cell>{officeName}</Cell>
            </Row>
            <Row>
                <Cell>Менеджер отдела продаж</Cell>
                <Cell>{car.SALESMAN || ''}</Cell>
            </Row>
            <Row>
                <Cell>Персональный менеджер</Cell>
                <Cell>{car.user?.login}</Cell>
                <Cell isEnd>
                    {!isManager && (
                        <SettingsButton onClick={() => onOpenManagerModal(true)}>
                            <SettingsIcon />
                        </SettingsButton>
                    )}
                </Cell>
            </Row>
            <Row>
                <Cell>ЖЦК</Cell>
                <Cell>{getLcr(car.lcr)}</Cell>
            </Row>
        </Container>
    );
};
