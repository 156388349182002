import moment, { Moment } from 'moment';

export const getDateRangeByIsoWeek = (weekNumber: number) => {
    return [moment().day(1).isoWeek(weekNumber), moment().day(7).isoWeek(weekNumber)];
};

export const getDatesByIsoWeek = (weekNumber: number) => {
    const dayNumbers = [1, 2, 3, 4, 5, 6, 7];
    return dayNumbers.map(el => moment().day(el).isoWeek(weekNumber));
};

export const getWeekByDate = (date: Date | string | Moment) => {
    const momentDate = moment(date);
    return momentDate.isoWeek();
};

export const getDatesInIsoWeekByDate = (date: Date | string | Moment) => {
    const dayNumbers = [1, 2, 3, 4, 5, 6, 7];
    return dayNumbers.map(el => moment(date).day(el).isoWeek(getWeekByDate(date)).toDate());
};

export const getDatesRangeInIsoWeekByDate = (date: Date | string | Moment) => {
    const dayNumbers = [1, 7];
    return dayNumbers.map((el, i) => {
        const momentDate = moment(date).day(el).isoWeek(getWeekByDate(date));
        if (i === 0) {
            return momentDate.set({ h: 0, m: 0, s: 0 }).toDate();
        }
        return momentDate.set({ h: 23, m: 59, s: 59 }).toDate();
    });
};

export const getYearWeekStringByDate = (date: Date | string | Moment) => {
    const momentDate = moment(date);
    return `${momentDate.isoWeek()}-я неделя ${momentDate.isoWeekYear()}`;
};
