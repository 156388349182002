export type orderByType = 'id' | 'CLIENT' | 'LCR_PERC';

type Column = {
    key: orderByType;
    value: string;
    sortable: boolean;
};

export const COLUMNS: Array<Column> = [
    {
        key: 'id',
        value: 'ID',
        sortable: true
    },
    {
        key: 'CLIENT',
        value: 'ФИО Клиента',
        sortable: true
    },
    {
        key: 'LCR_PERC',
        value: 'ЖЦК %',
        sortable: false
    }
];

export const ROWS_TEMPLATE = '3fr 3fr 2fr ';

export const getLcr = (lcr?: number | null) =>
    lcr !== undefined && lcr !== null ? `${lcr.toFixed(1).replace('.0', '')}%` : '';
