import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import calendarIconSrc from '../../../assets/images/calendar.svg';
import clientIconSrc from '../../../assets/images/clients.svg';
import tasksIconSrc from '../../../assets/images/tasks.svg';

const Icon = styled.img`
    width: 24px;
    margin-right: 13px;
`;

const Tab = styled(NavLink)`
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    padding: 20px 23px;
    background-color: #f6f7f8;
    box-shadow: initial;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;

    &.active {
        box-shadow: -1px -3px 13px -4px rgba(34, 60, 80, 0.14);
        background-color: #ffffff;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #000000;
`;

export const HeaderTabs = () => {
    return (
        <Wrapper>
            <Tab to="/calendar">
                <Icon src={calendarIconSrc} />
                Календарь
            </Tab>
            <Tab to="/clients">
                <Icon src={clientIconSrc} />
                Клиенты
            </Tab>
            <Tab to="/tasks">
                <Icon src={tasksIconSrc} />
                Задачи
            </Tab>
        </Wrapper>
    );
};
