import activeTaskSrc from '../assets/images/activeTask.svg';
import completedTaskSrc from '../assets/images/completedTask.svg';
import canceledTaskSrc from '../assets/images/canceledTask.svg';
import movedTaskSrc from '../assets/images/movedTask.svg';
import closedTaskSrc from '../assets/images/closedTask.svg';
import { TASK_STATUSES_ID } from '../modules/shared/constans';

type TaskInfo = {
    color: string;
    background: string;
    iconSrc: string | null;
    title: string;
};

export const getTaskRowStylesByStatus = (status: string): TaskInfo => {
    switch (status) {
        case TASK_STATUSES_ID.NEW: {
            return {
                color: '#292527',
                background: '#F6F7F8',
                title: 'Новая',
                iconSrc: null
            };
        }
        case TASK_STATUSES_ID.IN_PROGRESS: {
            return {
                color: '#679877',
                background: '#EDF6ED',
                title: 'На исполнении',
                iconSrc: activeTaskSrc
            };
        }
        case TASK_STATUSES_ID.IN_PROGRESS_DEPRECATED: {
            return {
                color: '#679877',
                background: '#EDF6ED',
                title: 'На выполнении',
                iconSrc: activeTaskSrc
            };
        }
        case TASK_STATUSES_ID.COMPLETED: {
            return {
                color: '#47A5DA',
                background: '#E0F4FA',
                title: 'Выполнена',
                iconSrc: completedTaskSrc
            };
        }
        case TASK_STATUSES_ID.CANCELED: {
            return {
                color: '#C75757',
                background: '#F7EFEB',
                title: 'Отменена',
                iconSrc: canceledTaskSrc
            };
        }
        case TASK_STATUSES_ID.MOVED: {
            return {
                color: '#CF7C3F',
                background: '#FFF5E3',
                title: 'Перенесена',
                iconSrc: movedTaskSrc
            };
        }
        case TASK_STATUSES_ID.CLOSED: {
            return {
                color: '#93949F',
                background: '#F6F7F8',
                title: 'Закрыта',
                iconSrc: closedTaskSrc
            };
        }
        default: {
            return {
                color: '#292527',
                background: '#F6F7F8',
                title: 'Новая',
                iconSrc: null
            };
        }
    }
};
