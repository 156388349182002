import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru';
import { groupBy, get } from 'lodash';

import { CalendarColumn, CalendarWeekPicker } from './components';
import { useCalendarTasksLazyQuery } from '../../graphql/api.types';
import { getDatesInIsoWeekByDate, getDatesRangeInIsoWeekByDate } from './utils';
import { DateType } from '../shared/components/Calendar/DatePicker';
import { Preloader, Select } from '../shared/components';
import { useCalendarFiltersContext } from './contexts/CalendarFiltersContext';
import { useAppState } from '../../AppState';

const Wrapper = styled.div`
    padding-top: 36px;
`;

const Container = styled.div`
    display: grid;
    overflow: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    margin-bottom: 40px;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 120%;
    color: #292527;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const PreloaderContainer = styled.div`
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

const InputWrapper = styled.div`
    width: 238px;
    background-color: #fff;
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    margin-left: 20px;

    &:first-child {
        margin-left: 0;
    }
`;

const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Calendar = () => {
    const defaultSelectedRange = getDatesRangeInIsoWeekByDate(new Date());

    const { managerId, setManagerId, selectedRange, setSelectedRange } = useCalendarFiltersContext();

    const [query, result] = useCalendarTasksLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        refetchWritePolicy: 'overwrite'
    });

    const { user } = useAppState();

    const generateSelectedRange = (d: DateType) => {
        if (d) {
            setSelectedRange(getDatesRangeInIsoWeekByDate(d));
        }
    };

    const staff = React.useMemo(
        () =>
            user?.staff?.map(s => ({
                title: s.login,
                value: s.id
            })) || [],
        [user]
    );

    const data = React.useMemo(() => {
        if (!result.data) return [];
        return groupBy(result.data.tasks, el =>
            moment(el.CRM_PLAN_DATE).locale('ru').format('dd DD.MM.yyyy').toUpperCase()
        );
    }, [result.data]);

    const generatedColumnsData = useMemo(() => {
        return getDatesInIsoWeekByDate(selectedRange[0]);
    }, [selectedRange]);

    useEffect(() => {
        if (selectedRange?.length) {
            query({
                variables: {
                    periodStart: selectedRange[0],
                    periodEnd: selectedRange[1],
                    managerId: managerId?.value
                }
            });
        }
    }, [selectedRange, query, managerId]);

    const renderManagerFilter = () => {
        if (!staff.length) {
            return null;
        }
        return (
            <InputWrapper>
                <Select
                    placeholder="Все менеджеры"
                    items={staff}
                    selectedItem={managerId}
                    onChange={setManagerId}
                    withEmptyValue={true}
                />
            </InputWrapper>
        );
    };

    const renderPreloader = () => {
        if (result.loading) {
            return (
                <PreloaderContainer>
                    <Preloader />
                </PreloaderContainer>
            );
        }
    };
    const renderContent = React.useCallback(() => {
        return generatedColumnsData.map((el, i) => {
            const momentDate = moment(el);
            const formattedDate = momentDate.locale('ru').format('dd DD.MM.yyyy').toUpperCase();
            const values = get(data, formattedDate, []);
            const isActive = momentDate.isSame(new Date(), 'day');
            return <CalendarColumn key={i} active={isActive} title={formattedDate} values={values} />;
        });
    }, [generatedColumnsData, data]);

    return (
        <Wrapper>
            <HeaderContainer>
                <Title>Календарь</Title>
                <FiltersWrapper>
                    <CalendarWeekPicker
                        value={selectedRange}
                        onChange={generateSelectedRange}
                        onReset={() => setSelectedRange(defaultSelectedRange)}
                    />
                    {renderManagerFilter()}
                </FiltersWrapper>
            </HeaderContainer>

            <Container>{renderContent()}</Container>
            {renderPreloader()}
        </Wrapper>
    );
};
