import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import orderTriangleIconSrc from '../../assets/images/orderTriangle.svg';
import { Preloader, TableRow, TextField } from '../shared/components';
import { SortOrder, useClientsQuery } from '../../graphql/api.types';
import { COLUMNS, ROWS_TEMPLATE, orderByType, getLcr } from './constans';
import { useDebounce } from '../shared/hooks';
import { useOnItemView } from '../shared/hooks/useOnItemView';
import { useClientsFiltersContext } from './contexts/filters';
import garbageIconSrc from '../../assets/images/garbage.svg';

const Wrapper = styled.div`
    padding-top: 36px;
`;

const InputWrapper = styled.div`
    width: 226px;
    background-color: #fff;
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const Table = styled.div`
    width: 100%;
`;

const HeaderCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #ffffff;
    cursor: pointer;

    &:last-child {
        justify-content: center;
    }
`;

const HeaderRow = styled(TableRow)`
    margin-bottom: 12px;
`;

const Row = styled(TableRow)`
    margin-bottom: 4px;
    cursor: pointer;
`;

const Cell = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #292527;

    &:last-child {
        text-align: center;
    }
`;

const PreloaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const OrderTriangle = styled.img.attrs({ src: orderTriangleIconSrc })<{ direction: SortOrder }>`
    width: 12px;
    margin-left: 6px;
    transform: ${({ direction }) => (direction === SortOrder.Desc ? 'initial' : 'rotate(180deg)')};
`;

const GarbageIcon = styled.img.attrs({ src: garbageIconSrc })`
    width: 14px;
    cursor: pointer;
`;

const Title = styled.div`
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 120%;
    color: #292527;
`;

//TODO refactor like Tasks

export const ClientsList = () => {
    const { search, onChange, sortDirection, orderBy, reset } = useClientsFiltersContext();
    const debouncedSearch = useDebounce(search, 500);

    const queryVariables = useMemo(
        () => ({
            orderBy: { [orderBy]: sortDirection },
            search: debouncedSearch,
            take: 100
        }),
        [orderBy, sortDirection, debouncedSearch]
    );

    const { loading, data, fetchMore } = useClientsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: { ...queryVariables, skip: 0 }
    });
    const clients = useMemo(
        () =>
            data?.clients.map(client => ({
                id: client.id,
                name: client.CLIENT || client.PM_CLIENT_NAME || null,
                lcr: client.lcr
            })) || [],
        [data]
    );

    const loadMoreCallback = useCallback(
        () => fetchMore({ variables: { ...queryVariables, skip: clients.length } }),
        [clients.length, queryVariables, fetchMore]
    );

    const [ref] = useOnItemView(loadMoreCallback);

    const onClickHeaderColumn = (columnName: orderByType) => {
        if (columnName === orderBy) {
            onChange({
                name: 'sortDirection',
                value: sortDirection === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
            });
        } else {
            onChange([
                { name: 'orderBy', value: columnName },
                { name: 'sortDirection', value: SortOrder.Asc }
            ]);
        }
    };
    const renderHeaderRow = () =>
        COLUMNS.map(el => {
            const onClick = el.sortable ? () => onClickHeaderColumn(el.key) : undefined;
            return (
                <HeaderCell onClick={onClick} key={el.key}>
                    {el.value}
                    {orderBy === el.key && <OrderTriangle direction={sortDirection} />}
                </HeaderCell>
            );
        });

    const renderClients = () => {
        const referredIndex = clients.length - 5 > 0 ? clients.length - 5 : clients.length - 1;
        return clients.map((client, index) => {
            const itemRef = index === referredIndex ? ref : undefined;
            return (
                <StyledLink key={client.id} to={`/clients/${client.id}?source=clients`}>
                    <Row template={ROWS_TEMPLATE} background={'#F6F7F8'} ref={itemRef}>
                        <Cell>{client.id}</Cell>
                        <Cell>{client.name}</Cell>
                        <Cell>{getLcr(client.lcr)}</Cell>
                    </Row>
                </StyledLink>
            );
        });
    };

    const renderPreloader = () => {
        if (loading) {
            return (
                <PreloaderContainer>
                    <Preloader />
                </PreloaderContainer>
            );
        }
    };
    return (
        <Wrapper>
            <Header>
                <Title>Клиенты</Title>
                <InputWrapper>
                    <TextField
                        placeholder="Поиск"
                        value={search}
                        onChange={e => onChange({ name: 'search', value: e.target.value })}
                    />
                </InputWrapper>
                <GarbageIcon onClick={reset} />
            </Header>

            <Table>
                <HeaderRow background={'#93949F'} template={ROWS_TEMPLATE}>
                    {renderHeaderRow()}
                </HeaderRow>
                {renderClients()}
            </Table>
            {renderPreloader()}
        </Wrapper>
    );
};
