import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Tasks } from '../modules/tasks';
import { Task } from '../modules/task';
import { Calendar } from '../modules/calendar';
import { ClientsList } from '../modules/clients';
import { Client } from '../modules/client';

export const UserRouter = () => (
    <Routes>
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/tasks/:id" element={<Task />} />
        <Route path="/clients" element={<ClientsList />} />
        <Route path="/clients/:id" element={<Client />} />
        <Route path="*" element={<Navigate to="/tasks" />} />
    </Routes>
);
