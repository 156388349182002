import React from 'react';
import styled from 'styled-components';

import { Button, TextField, Logo } from '../shared/components';
import { useSignInMutation } from '../../graphql/api.types';
import { graphQLFirstErrorLocaleExtractor, login as appLogin } from '../../utils';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e8e8e8;
`;

const MessageContainer = styled.div`
    margin-top: 60px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #292527;
`;

const ErrorMessageContainer = styled(MessageContainer)`
    color: #ec2830;
`;

const TextFieldContainer = styled.div`
    width: 314px;
    margin-bottom: 30px;

    &:first-child {
        margin-bottom: 14px;
    }
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Auth = () => {
    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [authError, setAuthError] = React.useState('');

    const [signInMutation] = useSignInMutation({
        onCompleted: data => {
            appLogin(data.signIn.token);
        }
    });

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthError('');
        setPassword(e.target.value);
    };
    const onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthError('');
        setLogin(e.target.value);
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await signInMutation({ variables: { login, password } }).catch(err => {
            if (err) setAuthError(graphQLFirstErrorLocaleExtractor(err));
        });
    };

    const renderMessage = () => {
        if (authError) return <ErrorMessageContainer>{authError}</ErrorMessageContainer>;
        return <MessageContainer>Введите свои учетные данные</MessageContainer>;
    };

    return (
        <Container>
            <Logo />
            {renderMessage()}
            <FormContainer onSubmit={onSubmit}>
                <TextFieldContainer>
                    <TextField placeholder="Логин" value={login} onChange={onLoginChange} error={!!authError} />
                </TextFieldContainer>
                <TextFieldContainer>
                    <TextField
                        placeholder="Пароль"
                        value={password}
                        type="password"
                        onChange={onPasswordChange}
                        error={!!authError}
                    />
                </TextFieldContainer>
                <Button disabled={!login || !password} type="submit">
                    Войти
                </Button>
            </FormContainer>
        </Container>
    );
};
