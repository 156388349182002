import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { Button, DatePicker, Modal, Select, TextField } from '../../shared/components';
import { TaskTitle } from './TaskTitle';
import { DropdownItem } from '../../shared/components/Select';
import { useTaskStatusesQuery, useCommentTemplatesLazyQuery } from '../../../graphql/api.types';
import { TASK_STATUSES_ID } from '../../shared/constans';
import { getTaskRowStylesByStatus } from '../../../utils';
import { CommentTemplates } from 'modules/task/components/ChangesHistory';

type onSubmitParams = {
    statusId: string;
    comment: string;
    id: string;
    date: Date | null;
    commentTemplateId?: string;
};

type CreateStatusModalProps = {
    isManager: boolean;
    isOpen: boolean;
    close: () => void;
    onSubmit: (v: onSubmitParams) => Promise<void>;
    commentTemplates: DropdownItem[];
} & CommentTemplates;

const Container = styled.form`
    background-color: #fff;
    padding: 20px;
`;

const Title = styled(TaskTitle)`
    margin-bottom: 20px;
`;

const FieldContainer = styled.div`
    border: 1px solid #eaebee;
    box-sizing: border-box;
    border-radius: 20px;
    width: 231px;
`;

const Row = styled.div`
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
`;

const Comment = styled.textarea`
    border: 1px solid #eaebee;
    width: 470px;
    height: 144px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    resize: none;
    margin-top: 20px;
    padding: 10px 14px;

    &::placeholder {
        color: #93949f;
    }
`;

const Error = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #ec2830;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
`;

const StyledButton = styled(Button)`
    margin-right: 6px;
`;

type formValues = {
    status: DropdownItem | null;
    template: DropdownItem | null;
    date: Date | null;
    time: string;
    comment: string;
};

type formErrors = {
    status?: string;
    template?: string;
    date?: string;
    time?: string;
    comment?: string;
};

export const CreateStatusModal = ({ isOpen, close, onSubmit, isManager, commentTemplates }: CreateStatusModalProps) => {
    const { data } = useTaskStatusesQuery();
    const params = useParams<{ id: string }>();

    const [loadCommentTemplates, commentTemplatesQuery] = useCommentTemplatesLazyQuery();

    const usedCommentTemplates = useMemo(() => {
        if (commentTemplates.length) return commentTemplates;
        return (
            commentTemplatesQuery.data?.findManyCommentTemplates.map(el => ({
                title: el.TEXT,
                value: el.id
            })) || []
        );
    }, [commentTemplatesQuery.data, commentTemplates]);

    const taskStatusesItems = React.useMemo<{ title: string; value: string }[]>(() => {
        const excludedManagerStatusesId = [TASK_STATUSES_ID.NEW];
        const excludedCommonStatusesId = [TASK_STATUSES_ID.CLOSED];

        if (data) {
            return data.taskStatuses
                .filter(el => {
                    if (!isManager) return !excludedCommonStatusesId.includes(el.id as TASK_STATUSES_ID);
                    return !excludedManagerStatusesId.includes(el.id as TASK_STATUSES_ID);
                })
                .map(el => ({
                    title: getTaskRowStylesByStatus(el.id).title,
                    value: el.id
                }));
        }
        return [];
    }, [data, isManager]);

    const formData = useFormik<formValues>({
        initialValues: {
            status: null,
            template: null,
            date: null,
            time: '',
            comment: ''
        },
        onSubmit: values => {
            const data: { comment: string; status: string; date?: string } = {
                comment: values.comment,
                status: values.status?.value || ''
            };
            if (values.status?.title === 'Перенесена' && values.date && values.time) {
                const date = new Date(values.date);
                const splittedTime = values.time.split(':');
                date.setHours(parseInt(splittedTime[0]), parseInt(splittedTime[1]));
                data.date = date.toISOString();
            }

            if (values.status && params.id) {
                if (values.date && values.time) {
                    const timeArray = values.time.split(':');
                    values.date.setHours(parseInt(timeArray[0]));
                    values.date.setMinutes(parseInt(timeArray[1]));
                }
                onSubmit({
                    comment: values.comment,
                    statusId: values.status.value,
                    id: params.id,
                    date: values.date,
                    commentTemplateId: values?.template?.value
                });
                close();
            }
        },
        validate: values => {
            const errors: formErrors = {};
            if (!values.status) {
                errors.status = 'Выберите статус';
            }
            if (!values.comment) {
                errors.comment = 'Введите комментарий';
            }
            if (values.status?.title === 'Перенесена' && (!values.date || !values.time)) {
                errors.date = 'Укажите дату и время';
            }
            return errors;
        }
    });

    const { resetForm } = formData;

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [resetForm, isOpen]);

    const onChangeTemplate = (v: DropdownItem | null) => {
        formData.setFieldValue('template', v);
        formData.setFieldValue('comment', v?.title || '');
    };

    const onChangeStatus = (v: DropdownItem | null) => {
        formData.setFieldValue('template', null);
        formData.setFieldValue('comment', '');
        formData.setFieldValue('status', v);
    };

    const renderDateRow = () => {
        if (formData.values.status?.title === 'Перенесена') {
            return (
                <Row>
                    <FieldContainer>
                        <DatePicker
                            minDate={new Date()}
                            value={formData.values.date}
                            onChange={v => formData.setFieldValue('date', v)}
                            placeholder="Выберите дату"
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <TextField
                            name="time"
                            placeholder="Введите время"
                            type="time"
                            value={formData.values.time}
                            onChange={formData.handleChange}
                        />
                    </FieldContainer>
                </Row>
            );
        }
        return null;
    };

    const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        formData.handleChange(e);
        // if (
        //   (formData.values.template && formData.values.comment !== formData.values.template.title) ||
        //   formData.values.template === null
        // ) {
        // TODO: придумать как убрать хардкод '01_другое' например по ID
        const otherCommentTemplate = usedCommentTemplates.find(
            ({ title }) => title.toLocaleLowerCase() === '01_другое'
        );
        if (otherCommentTemplate && !formData.values.template) {
            formData.values.template = otherCommentTemplate;
        }
        // }
    };

    useEffect(() => {
        !commentTemplates.length && loadCommentTemplates();
    }, [commentTemplates, loadCommentTemplates]);

    return (
        <Modal isOpen={isOpen}>
            <Container onSubmit={formData.handleSubmit}>
                <Title>Новый статус задачи</Title>
                <Row>
                    <FieldContainer>
                        <Select
                            withEmptyValue={false}
                            placeholder="Выберите статус"
                            items={taskStatusesItems}
                            selectedItem={formData.values.status || null}
                            onChange={onChangeStatus}
                        />
                    </FieldContainer>
                </Row>
                {formData.errors.status && <Error>{formData.errors.status}</Error>}
                {renderDateRow()}
                {formData.errors.date && <Error>{formData.errors.date}</Error>}
                <Row>
                    <FieldContainer>
                        <Select
                            withEmptyValue={true}
                            placeholder="Выберите причину изменения статуса"
                            items={usedCommentTemplates}
                            selectedItem={formData.values.template || null}
                            onChange={onChangeTemplate}
                        />
                    </FieldContainer>
                </Row>
                <Comment
                    name="comment"
                    placeholder="Введите комментарий к причине изменения статуса"
                    value={formData.values.comment}
                    onChange={onChangeComment}
                />
                {formData.errors.comment && <Error>{formData.errors.comment}</Error>}
                <ButtonsContainer>
                    <StyledButton theme="secondary" type="reset" onClick={close}>
                        Отмена
                    </StyledButton>
                    <Button type="submit">Сохранить</Button>
                </ButtonsContainer>
            </Container>
        </Modal>
    );
};
