import { Modal, ModalProps } from 'modules/shared/components/Modal';
import { FlexGrow, Row } from '../../wrappers/grid/FlexWrapper';
import {
    ColumnHeader,
    InputsWrapper,
    InputWrapper,
    ModalContainer,
    ReportOptionsWrapper,
    ReportOptionTitle,
    ReportOptionValue,
    ReportOptionWrapper
} from './styles';
import { ModalTitle } from 'modules/shared/components/modals/styles';
import {
    ColumnHeaderMarginBottom,
    initialValues,
    reportingOptionsTitle
} from 'modules/shared/components/modals/ReportingModal/constants';
import React, { useEffect, useState } from 'react';
import {
    ReportChangeValues,
    ReportingOptions,
    TagsOptions,
    TextOptions
} from 'modules/shared/components/modals/ReportingModal/types';
import { DropdownItem, UISelect } from 'modules/shared/components/Select';
import { MarginWrapper } from 'modules/shared/components/wrappers/grid/MarginWrapper';
import { Button } from 'modules/shared/components/Button';
import { DivWrapper } from 'modules/shared/components/wrappers/grid/DivWrapper';
import { Title } from 'types/data';
import { useExecuteReportMutation, useReportsLazyQuery } from 'graphql/api.types';
import { useAppState } from 'AppState';
// import { formatDateRangeToString, UIDateRangePicker } from 'modules/shared/components/Calendar/DateRangePicker';
// import { Selector } from 'modules/shared/components/inputs/Selector';
// import { SelectorOption } from 'modules/shared/components/inputs/Selector/types';

type ReportOptionProps = Title & (TextOptions | TagsOptions);

const ReportOption = ({ title, type = 'text', value }: ReportOptionProps) => {
    return (
        <ReportOptionWrapper>
            <ReportOptionTitle>{title}</ReportOptionTitle>
            {type === 'text' ? (
                <ReportOptionValue>{value as string}</ReportOptionValue>
            ) : (
                <ReportOptionValue>test</ReportOptionValue>
            )}
        </ReportOptionWrapper>
    );
};

interface ReportingModalProps extends Pick<ModalProps, 'isOpen'> {
    onClose: () => void;
}

export const ReportingModal = ({ isOpen, onClose }: ReportingModalProps) => {
    const [reportingOptions, setReportingOptions] = useState<ReportingOptions>(initialValues);

    const {
        permissions: { isDirector }
    } = useAppState();

    const [loading, setLoading] = useState(false);
    const [loadReports, { data: reports }] = useReportsLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network'
    });
    const reportTypes: DropdownItem[] = (reports?.reports || []).map(({ name, description }) => ({
        title: description,
        value: name
    }));

    const [executeReportMutation] = useExecuteReportMutation();

    const onOptionsChange = ({ name, value }: ReportChangeValues) => {
        setReportingOptions(values => ({ ...values, [name]: value }));
    };

    // const { reportType, period, cities: city } = reportingOptionsTitle;
    const { reportType } = reportingOptionsTitle;

    const onReset = () => {
        setReportingOptions(initialValues);
        onClose();
    };

    const onLoad = async () => {
        if (reportingOptions.reportType) {
            setLoading(true);
            const { data } = await executeReportMutation({ variables: { name: reportingOptions.reportType.value } });
            setLoading(false);
            const reportUrl = data?.executeReport?.fileUrl;
            if (reportUrl) {
                onReset();
                location.href = reportUrl;
            }
        }
    };

    useEffect(() => {
        if (isDirector) {
            loadReports();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirector]);

    return (
        <Modal isOpen={isOpen}>
            <ModalContainer>
                <Row width="100%" alignCenter justifyCenter>
                    <ModalTitle>Сформировать отчет</ModalTitle>
                </Row>
                <Row>
                    <FlexGrow marginRight="40px" flexGrow="0" width="230px" flexBasis="230px">
                        <ColumnHeader>Фильтры</ColumnHeader>
                        <InputsWrapper marginTop={ColumnHeaderMarginBottom}>
                            <InputWrapper>
                                <UISelect
                                    width="100%"
                                    placeholder={reportType}
                                    label={reportType}
                                    items={reportTypes}
                                    selectedItem={reportingOptions.reportType}
                                    onChange={value => onOptionsChange({ name: 'reportType', value })}
                                    withEmptyValue={false}
                                />
                            </InputWrapper>
                            {/*<InputWrapper>*/}
                            {/*    <UIDateRangePicker*/}
                            {/*        width="100%"*/}
                            {/*        label={period}*/}
                            {/*        placeholder={period}*/}
                            {/*        value={reportingOptions.period}*/}
                            {/*        onChange={value => onOptionsChange({ name: 'period', value })}*/}
                            {/*    />*/}
                            {/*</InputWrapper>*/}
                            {/*<InputWrapper>*/}
                            {/*    <Selector*/}
                            {/*        onChange={value =>*/}
                            {/*            onOptionsChange({ name: 'cities', value: value as SelectorOption[] })*/}
                            {/*        }*/}
                            {/*        isMulti*/}
                            {/*        value={reportingOptions.cities}*/}
                            {/*        options={cities}*/}
                            {/*        placeholder={city}*/}
                            {/*    />*/}
                            {/*</InputWrapper>*/}
                        </InputsWrapper>
                    </FlexGrow>
                    <FlexGrow flexGrow="1">
                        <ColumnHeader>Параметры отчета</ColumnHeader>
                        <ReportOptionsWrapper marginTop={ColumnHeaderMarginBottom}>
                            <ReportOption title={reportType} value={reportingOptions.reportType?.title} />
                        </ReportOptionsWrapper>
                        {/*<ReportOptionsWrapper>*/}
                        {/*    <ReportOption title={period} value={formatDateRangeToString(reportingOptions.period)} />*/}
                        {/*</ReportOptionsWrapper>*/}
                    </FlexGrow>
                </Row>
                <Row justifyEnd marginTop="20px">
                    <MarginWrapper marginRight="6px">
                        <Button onClick={onReset} theme="secondary">
                            отмена
                        </Button>
                    </MarginWrapper>
                    <DivWrapper>
                        <Button loading={loading} disabled={loading || !reportingOptions?.reportType} onClick={onLoad}>
                            Выгрузить данные
                        </Button>
                    </DivWrapper>
                </Row>
            </ModalContainer>
        </Modal>
    );
};
