import { css } from 'styled-components';
import { Margin } from 'types/styles';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const margin = css<Margin>`
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
`;
