import { TaskQuery } from '../../../graphql/api.types';
import moment from 'moment';

export const generateTaskTablesValues = (task: TaskQuery['task']) => {
    if (!task) return;
    const { client, car, point, office, company } = task;

    return {
        info: [
            {
                title: 'ID',
                value: task.ID_COL
            },
            {
                title: 'Год плана',
                value: task.PLAN_YEAR
            },
            {
                title: 'Месяц плана',
                value: task.PLAN_MONTH
            },
            {
                title: 'Очередность ТБ',
                value: task.POINT_PRIORITY
            },
            {
                title: 'Тип ТБ',
                value: point.POINT_TYPE
            },
            {
                title: 'Описание ТБ',
                value: point.POINT_NAME
            }
        ],

        car: [
            {
                title: 'Бренд',
                value: car.BRAND
            },
            {
                title: 'Модель',
                value: car.CAR_MODEL
            },
            {
                title: 'Комплектация',
                value: car.CAR_EQUIPMENT
            },
            {
                title: 'VIN',
                value: car.CAR_VIN
            },
            {
                title: 'Количество месяцев после выдачи',
                value: moment
                    .duration(moment(new Date()).diff(moment(task.car.DATE_RELEASE)), 'millisecond')
                    .asMonths()
                    .toFixed(2)
            },
            {
                title: 'Пробег',
                value: car?.telematic?.mileage
            },
            {
                title: 'Компания',
                value: company.ORDER_COMPANY
            },
            {
                title: 'Город',
                value: office.CITY
            },
            {
                title: 'ID ДЦ',
                value: office.DEALERID
            },
            {
                title: 'Наименование ДЦ',
                value: office.NAME_AX
            }
        ],
        client: [
            {
                title: 'ФИО',
                value: client.CLIENT || ''
            },
            {
                title: 'ФИО доверенного лица',
                value: client.PM_CLIENT_NAME || ''
            },
            {
                title: 'Контактный номер',
                value: client.CLIENT_PHONE
            }
        ]
    };
};
