import styled, { css } from 'styled-components';
import { useState, useRef } from 'react';

import selectPolygonSrc from '../../../assets/images/selectPolygon.svg';
import { useOnClickOutside } from '../hooks';
import { Sizes } from 'types/styles';

export type DropdownItem = {
    title: string;
    value: string;
};

interface SelectProps {
    items: DropdownItem[];
    label?: string;
    selectedItem: DropdownItem | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (v: DropdownItem | null) => any;
    placeholder: string;
    withEmptyValue?: boolean;
    withReselect?: boolean;
}

export const inputMixin = css`
    padding: 12px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    ${inputMixin};
    cursor: pointer;
    border-radius: inherit;
`;
export const SelectPolygon = styled.img.attrs({ src: selectPolygonSrc })`
    width: 12px;
`;
const Placeholder = styled.div`
    color: #93949f;
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    user-select: none;
`;

export const itemsContainerMixin = css`
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    background-color: #fff;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 10;
    top: 45px;
    max-height: 250px;
    overflow: auto;
    ${itemsContainerMixin};
`;

export const itemContainerMixin = css`
    padding: 12px 15px;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f2;
    &:hover {
        background-color: #f6f7f8;
    }
`;

const ItemContainer = styled.div`
    width: 100%;
    cursor: pointer;
    ${itemContainerMixin};
`;

const Title = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Label = styled.label`
    position: absolute;
    left: 14px;
    background: white;
    top: -5px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #93949f;
`;

export const borderMixin = css`
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
`;

const UIWrapper = styled.div<Sizes>`
    width: ${({ width }) => width || '100px'};
    height: ${({ height }) => height || '40px'};
    background-color: #fff;
    ${borderMixin};
    margin-right: 20px;
`;

export const Select = ({
    items,
    label,
    selectedItem,
    onChange,
    placeholder,
    withEmptyValue = true,
    withReselect = false
}: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef();

    useOnClickOutside(ref, () => setIsOpen(false));

    const onClickItem = (value: DropdownItem | null) => {
        onChange(withReselect && selectedItem && value && selectedItem.value === value.value ? null : value);
        setIsOpen(false);
    };

    const renderItems = () =>
        items.map(item => (
            <ItemContainer key={item.value} onClick={() => onClickItem(item)}>
                {item.title}
            </ItemContainer>
        ));

    const renderEmptyValue = () => {
        if (withEmptyValue) return <ItemContainer onClick={() => onClickItem(null)}>{placeholder}</ItemContainer>;
    };
    const renderDropDown = () => {
        if (!isOpen) return null;
        return (
            //@ts-ignore
            <ItemsContainer ref={ref}>
                {renderEmptyValue()}
                {renderItems()}
            </ItemsContainer>
        );
    };
    const renderTitle = () => {
        if (selectedItem) return <Title>{selectedItem.title}</Title>;
        return <Placeholder>{placeholder}</Placeholder>;
    };

    return (
        <Wrapper>
            {label && selectedItem && <Label>{label}</Label>}
            <Container onClick={() => setIsOpen(!isOpen)}>
                {renderTitle()}
                <SelectPolygon />
            </Container>
            {renderDropDown()}
        </Wrapper>
    );
};

interface UISelectProps extends SelectProps, Sizes {}

export const UISelect = ({ width, height, ...props }: UISelectProps) => (
    <UIWrapper width={width} height={height}>
        <Select {...props} />
    </UIWrapper>
);
