import styled from 'styled-components';

import { getYearWeekStringByDate } from '../utils';
import { DatePicker } from '../../shared/components';
import { DateType } from '../../shared/components/Calendar/DatePicker';

const Container = styled.div`
    width: 163px;
    background-color: #fff;
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
`;

const ResetButton = styled.button`
    outline: none;
    border: none;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #93949f;
    margin-right: 15px;
    background-color: #fff;
    cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

type CalendarRangePickerProps = {
    onChange: (v: DateType) => void;
    value: Array<Date>;
    onReset: () => void;
};

export const CalendarWeekPicker = ({ onChange, onReset, value }: CalendarRangePickerProps) => {
    const currentWeekYearString = getYearWeekStringByDate(new Date());
    const selectedWeekYearString = getYearWeekStringByDate(value[0]);

    const renderResetButton = () => {
        if (selectedWeekYearString !== currentWeekYearString)
            return <ResetButton onClick={onReset}>Вернуться к текущей</ResetButton>;
    };

    return (
        <Wrapper>
            {renderResetButton()}
            <Container>
                <DatePicker
                    onReset={onReset}
                    value={value}
                    placeholder="Выберите неделю"
                    onChange={onChange}
                    selectByWeek={true}
                    formattedValue={selectedWeekYearString}
                />
            </Container>
        </Wrapper>
    );
};
