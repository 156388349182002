import styled from 'styled-components';

interface TextFieldProps {
    error?: boolean;
}

export const TextField = styled.input<TextFieldProps>`
    width: 100%;
    height: 40px;
    padding: 12px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #292527;
    outline: none;
    border-radius: inherit;
    border: ${({ error }) => (error ? '1px solid #EC2830' : 'none')};

    &::placeholder {
        color: #93949f;
    }
`;
