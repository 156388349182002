import styled from 'styled-components';

export const ModalContainer = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 600px;
    max-height: 400px;
    background-color: #ffffff;
`;

export const ModalTitle = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    padding: 0;
    color: #292527;
`;

export const ModalText = styled(ModalTitle)`
    font-weight: 400;
    text-align: center;
`;
