export const TASK_TABLE_TEMPLATE_ROWS = '.9fr 1.4fr .9fr .6fr 1.4fr 1.1fr 1.3fr .7fr';

type Column = {
    key: string;
    value: string;
    sortable: boolean;
    content?: string;
};

export const COLUMNS: Array<Column> = [
    {
        key: '№',
        value: 'ID_COL',
        sortable: true
    },
    {
        key: 'Задача',
        value: 'POINT_NAME',
        sortable: true
    },
    {
        key: 'Дата плана',
        value: 'CRM_PLAN_DATE',
        sortable: true,
        content: 'center'
    },
    {
        key: 'Время',
        value: 'time',
        sortable: false,
        content: 'center'
    },
    {
        key: 'ФИО Клиента',
        value: 'CLIENT_OR_PM_CLIENT_NAME',
        sortable: false
    },
    {
        key: 'Телефон',
        value: 'CLIENT_PHONE',
        sortable: false
    },
    {
        key: 'Автомобиль',
        value: 'auto',
        sortable: false
    },
    {
        key: 'Статус',
        value: 'taskStatusId',
        sortable: true
    }
];
