import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getTaskRowStylesByStatus } from '../../../utils';
import { TaskQuery } from '../../../graphql/api.types';
import moment from 'moment';
import { TASK_STATUSES_ID } from '../../shared/constans';
import { ReactComponent as WiFiIcon } from 'assets/images/wifi.svg';

const Container = styled(Link)<{ background?: string; color?: string }>`
    position: relative;
    padding: 5px 10px;
    background: ${({ background }) => (background ? background : '#F6F7F8')};
    color: ${({ color }) => (color ? color : '#6B6C76')};
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 130%;
    width: 100%;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    text-decoration: none;
`;

const IconWrapper = styled.div<{ color?: string }>`
    position: absolute;
    right: 4px;
    top: 4px;
    svg {
        path {
            stroke: ${({ color }) => (color ? color : '#6B6C76')};
        }
    }
`;

const Icon = styled.img`
    width: 12px;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

const Text = styled.div`
    word-break: break-word;
`;

const ClientNameText = styled(Text)<{ isNewStatus: boolean }>`
    color: ${({ isNewStatus }) => (isNewStatus ? '#93949F' : 'inherit')};
`;

const TimeContainer = styled(ClientNameText)`
    margin-right: 5px;
    word-break: initial;
`;

type CalendarColumnItemProps = {
    task: TaskQuery['task'];
};

export const CalendarColumnItem = ({ task }: CalendarColumnItemProps) => {
    if (!task) return null;
    const styles = getTaskRowStylesByStatus(task.status.id);
    const isNewStatus = task.status.id === TASK_STATUSES_ID.NEW;

    return (
        <Container background={styles.background} color={styles.color} to={`/tasks/${task.id}?source=calendar`}>
            <Text>{task.point.POINT_NAME}</Text>
            {/* @ts-ignore */}
            {task.car?.telematic && (
                <IconWrapper color={styles.color}>
                    <WiFiIcon />
                </IconWrapper>
            )}
            <TextContainer>
                <TimeContainer isNewStatus={isNewStatus}>
                    {moment(task.CRM_PLAN_DATE).format('HH:mm')}
                    {styles.iconSrc && <Icon src={styles.iconSrc} />}
                </TimeContainer>
                <ClientNameText isNewStatus={isNewStatus}>
                    {task.client.CLIENT || task.client.PM_CLIENT_NAME}
                </ClientNameText>
            </TextContainer>
        </Container>
    );
};
