import styled, { css } from 'styled-components';
import { borderMixin, inputMixin, itemContainerMixin, itemsContainerMixin } from 'modules/shared/components/Select';

export const comboboxStyleMixin = css`
    cursor: pointer;
    .cb__menu-portal {
        z-index: 10;
    }

    .cb__placeholder {
        white-space: nowrap;
        text-align: left;
    }

    .cb__control {
        ${borderMixin};
        cursor: pointer;
    }

    .cb__control--is-focused {
        ${borderMixin};
        box-shadow: none;
    }

    .cb__indicator-separator {
        display: none;
    }

    .cb__value-container {
        ${inputMixin};
        padding-top: 0;
        padding-bottom: 0;
    }

    .cb__indicator {
        svg {
            color: #93949f;
        }
    }

    .cb__indicator:hover {
        opacity: 0.7;
    }

    .cb__menu {
        ${itemsContainerMixin};
        box-shadow: none;

        .cb__menu-list {
            padding: 0;
        }

        .cb__option {
            ${itemContainerMixin};
            cursor: pointer;
        }

        .cb__menu-notice {
            ${itemContainerMixin};
        }
    }
`;

export const StyleWrapper = styled.div`
    width: 100%;

    .combobox {
        ${comboboxStyleMixin}
    }
`;

export const DropdownWrapper = styled.div`
    width: 27px;
`;
