import Select, { components, OptionProps, Props, ValueContainerProps } from 'react-select';
import { OptionStyles, StyleWrapper, ValueContainerStyles } from 'modules/shared/components/inputs/Selector/styles';
import { SelectorOption } from 'modules/shared/components/inputs/Selector/types';
import { Children, MouseEvent } from 'react';
import { DropdownIndicator } from 'modules/shared/components/inputs/Combobox';
import { WithPortal } from 'types/data';

const ValueContainer = ({ children, ...props }: ValueContainerProps<SelectorOption>) => {
    const textValue = (props.selectProps.value as SelectorOption[]).map(({ label }) => label).join(', ');

    const selectInput = Children.map(children, (child, index) => {
        if (index === Children.count(children) - 1) {
            return child;
        }
    });

    const placeholder = Children.map(children, child => {
        //@ts-ignore
        if (child.key === 'placeholder') {
            return child;
        }
    });

    return (
        <components.ValueContainer {...props}>
            {placeholder}
            {textValue && <ValueContainerStyles>{textValue}</ValueContainerStyles>}
            {selectInput}
        </components.ValueContainer>
    );
};

const Option = ({ label, isSelected, selectOption, data }: OptionProps<SelectorOption>) => {
    const onSelect = () => selectOption(data);

    const onClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onSelect();
    };

    return (
        <OptionStyles onClick={onClick} className="selector__option">
            <input checked={isSelected} onChange={onSelect} type="checkbox" />
            <span onClick={onClick}>{label}</span>
        </OptionStyles>
    );
};

interface SelectorProps
    extends Pick<Props, 'name' | 'options' | 'defaultValue' | 'value' | 'placeholder' | 'onChange' | 'isClearable'>,
        WithPortal {
    isMulti?: boolean;
    isSearchable?: boolean;
    closeMenuOnSelect?: boolean;
}

export const Selector = ({
    isSearchable = false,
    isClearable = false,
    closeMenuOnSelect = false,
    isMulti,
    withPortal,
    ...selectProps
}: SelectorProps) => (
    <StyleWrapper>
        <Select
            {...selectProps}
            hideSelectedOptions={false}
            maxMenuHeight={200}
            isSearchable={isSearchable}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={isMulti}
            components={{
                DropdownIndicator,
                //@ts-ignore
                Option: isMulti ? Option : components.Option,
                //@ts-ignore
                ValueContainer: isMulti ? ValueContainer : components.ValueContainer
            }}
            className="custom-selector"
            classNamePrefix="selector"
            menuPortalTarget={withPortal ? (document.querySelector('#portalRoot') as HTMLElement) : undefined}
        />
    </StyleWrapper>
);
