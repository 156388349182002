import styled from 'styled-components';

import { logout } from '../../../utils';
import { useAppState } from '../../../AppState';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const UserLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 130%;
    color: #93949f;
    margin-right: 5px;
`;

const UserName = styled.div`
    font-size: 10px;
    line-height: 130%;
    text-align: right;
    color: #292527;
    margin-right: 20px;
`;

const LogoutButton = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    color: #6b6c76;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;

    :hover {
        text-decoration: underline;
    }
`;

export const HeaderLogout = () => {
    const { user } = useAppState();

    const renderUserName = () => {
        if (user?.login) return <UserName>{user?.login}</UserName>;
        return <UserName>Загрузка...</UserName>;
    };
    return (
        <Wrapper>
            <UserLabel>Пользователь:</UserLabel>
            {renderUserName()}
            <LogoutButton onClick={logout}>Выйти</LogoutButton>
        </Wrapper>
    );
};
