import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { get, uniqBy } from 'lodash';

import { Title } from './Title';
import { DateRangePicker, DateRangeType } from '../../shared/components/Calendar/DateRangePicker';
import { usePointQuery, useTasksByClientQuery } from '../../../graphql/api.types';
import { Preloader, Select, TableRow } from '../../shared/components';
import { getTaskRowStylesByStatus } from '../../../utils';
import moment from 'moment';
import { DropdownItem } from '../../shared/components/Select';
import garbageIconSrc from '../../../assets/images/garbage.svg';

const Wrapper = styled.div`
    margin-top: 60px;
    margin-bottom: 60px;
`;

const InputsContainer = styled.div`
    margin-top: 17px;
    margin-bottom: 27px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
`;

const InputWrapper = styled.div`
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    width: 230px;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
`;

const CarInputWrapper = styled(InputWrapper)`
    width: 300px;
`;

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const TasksHistoryContainer = styled.div`
    width: 33%;
`;

const StatusHistoryContainer = styled.div`
    width: 65%;
`;

const StatusHistoryItem = styled.div`
    padding: 12px 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    background: #f6f7f8;
    min-height: 250px;
    width: 100%;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`;
const StatusHistoryDate = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #6b6c76;
`;
const StatusHistoryTitle = styled.div`
    font-weight: bold;
    margin-right: 10px;
`;

const StatusHistoryRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`;

const TasksHistoryItem = styled(TableRow)<{
    selected: boolean;
    closest: boolean;
    color: string;
    background: string;
}>`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    margin-bottom: 4px;
    font-weight: ${({ closest }) => (closest ? 'bold' : 'normal')};
    color: ${({ color }) => (color ? color : '#93949F')};
    border: ${({ color, selected }) => (selected ? `1px solid ${color}` : 'none')};
    background-color: ${({ background }) => (background ? background : '#93949F')};

    &:last-child {
        margin-bottom: 0;
    }
`;

const StatusImage = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const StatusImagePlaceholder = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const Cell = styled.div`
    display: flex;
    align-items: center;
    font-style: normal;
    color: black;
    font-size: 12px;
    line-height: 130%;
    padding-right: 10px;
`;

const GarbageIcon = styled.img.attrs({ src: garbageIconSrc })`
    width: 14px;
    cursor: pointer;
`;

const PreloaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;
`;

type HistoryProps = {
    clientId?: string;
    cars: { CAR_MODEL?: string | null; BRAND?: string | null; id: string; CAR_VIN?: string }[];
};

export const History = ({ clientId, cars }: HistoryProps) => {
    const [period, setPeriod] = useState<DateRangeType>(null);

    const [selectedPointType, setSelectedPointType] = useState<DropdownItem | null>(null);

    const [selectedCarId, setSelectedCarId] = useState<DropdownItem | null>(null);

    const resetFilters = () => {
        setSelectedPointType(null);
        setPeriod(null);
        setSelectedCarId(null);
    };

    const { data, loading, refetch } = useTasksByClientQuery({
        variables: { clientId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });
    const [selectedTaskIndex, setSelectedTaskIndex] = useState<number | undefined>();

    const points = usePointQuery();

    const pointsSelectData = useMemo(() => {
        if (!points.data?.pointTypes) {
            return [];
        }
        const pointTypes = points.data.pointTypes;

        return uniqBy(pointTypes, p => p.POINT_TYPE).map(p => ({
            title: p.POINT_NAME,
            value: p.POINT_TYPE
        }));
    }, [points]);

    const carsSelectData = useMemo(() => {
        return cars.map(c => ({
            title: `${c.BRAND} ${c.CAR_MODEL}  (${c.CAR_VIN})`,
            value: c.id
        }));
    }, [cars]);

    const tasks = useMemo(() => {
        if (!data?.tasks) return [];

        return data.tasks.map(t => ({
            ...t,
            statusHistory: [
                {
                    id: null,
                    deletedAt: t.CURRENT_STATUS_DATE,
                    LAST_COMMENT_CLIENT: t.LAST_COMMENT_CLIENT,
                    taskStatusId: t.status.id
                },
                ...t.statusHistory
            ]
        }));
    }, [data]);

    const selectedTaskStatusesHistory = useMemo(
        () => get(tasks, `[${selectedTaskIndex}.statusHistory]`, []),
        [selectedTaskIndex, tasks]
    );

    useEffect(() => {
        const tasks = data?.tasks;
        if (tasks && tasks.length > 0) {
            setSelectedTaskIndex(0);
        }
    }, [data]);

    useEffect(() => {
        const variables = {
            clientId,
            periodStart: get(period, '[0]', undefined),
            periodEnd: get(period, '[1]', undefined),
            pointType: selectedPointType?.value || undefined,
            carId: selectedCarId?.value || undefined
        };
        refetch(variables);
    }, [period, clientId, selectedPointType, selectedCarId, refetch]);

    const renderIcon = (src: string | null) => {
        if (!src) return <StatusImagePlaceholder />;
        return <StatusImage src={src} />;
    };

    const renderTasksHistoryItem = () =>
        tasks.map((t, i) => {
            const planDate = moment(t.CRM_PLAN_DATE);
            const closest = planDate.isBetween(moment(new Date()), moment(new Date()).add(30, 'days'));
            const styles = getTaskRowStylesByStatus(t.status.id);
            const selected = selectedTaskIndex === i;
            return (
                <TasksHistoryItem
                    color={styles.color}
                    selected={selected}
                    closest={closest}
                    background={styles.background}
                    key={t.id}
                    onClick={() => setSelectedTaskIndex(i)}
                >
                    {selected ? (
                        <Link to={`/tasks/${t.id}`}>
                            <Cell>
                                {renderIcon(styles.iconSrc)}
                                {t.point.POINT_NAME}
                            </Cell>
                        </Link>
                    ) : (
                        <Cell>
                            {renderIcon(styles.iconSrc)}
                            {t.point.POINT_NAME}
                        </Cell>
                    )}
                    <Cell>{planDate.format('DD.MM.YYYY')}</Cell>
                </TasksHistoryItem>
            );
        });

    const renderStatusHistoryItem = () =>
        //@ts-ignore
        selectedTaskStatusesHistory.map(h => {
            const styles = getTaskRowStylesByStatus(h.taskStatusId);
            return (
                <StatusHistoryItem key={h.id}>
                    <StatusHistoryRow>
                        <StatusHistoryTitle>Статус:</StatusHistoryTitle>
                        <div style={{ color: styles.color }}>{styles.title}</div>
                    </StatusHistoryRow>
                    <StatusHistoryRow>
                        <StatusHistoryTitle> Дата смены статуса:</StatusHistoryTitle>
                        <StatusHistoryDate>{moment(h.deletedAt).format('DD.MM.YYYY')}</StatusHistoryDate>
                    </StatusHistoryRow>
                    <StatusHistoryRow>
                        <StatusHistoryTitle>Комментарий:</StatusHistoryTitle>
                        <div>{h.LAST_COMMENT_CLIENT || 'Отсутствует'} </div>
                    </StatusHistoryRow>
                </StatusHistoryItem>
            );
        });

    const renderContent = () => {
        if (loading)
            return (
                <PreloaderContainer>
                    <Preloader />
                </PreloaderContainer>
            );
        return (
            <Container>
                <TasksHistoryContainer>{renderTasksHistoryItem()}</TasksHistoryContainer>
                <StatusHistoryContainer>{renderStatusHistoryItem()}</StatusHistoryContainer>
            </Container>
        );
    };

    return (
        <Wrapper>
            <Title>Плановые работы и история взаимодействия</Title>
            <InputsContainer>
                <InputWrapper>
                    <DateRangePicker value={period} onChange={setPeriod} />
                </InputWrapper>
                <InputWrapper>
                    <Select
                        items={pointsSelectData}
                        selectedItem={selectedPointType}
                        onChange={setSelectedPointType}
                        placeholder="Все типы работ"
                    />
                </InputWrapper>
                <CarInputWrapper>
                    <Select
                        items={carsSelectData}
                        selectedItem={selectedCarId}
                        onChange={setSelectedCarId}
                        placeholder="Все автомобили"
                    />
                </CarInputWrapper>
                <GarbageIcon onClick={resetFilters} />
            </InputsContainer>
            {renderContent()}
        </Wrapper>
    );
};
