import styled from 'styled-components';

export const Title = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    margin-bottom: 14px;
    padding-left: 15px;
    color: #292527;
`;
