import { ApolloError } from '@apollo/client';
import { first } from 'lodash';
import { getEnumValueByKey } from './getEnumValueByKey';
import { ERRORS } from '../modules/shared/constans';

export const graphQLFirstErrorLocaleExtractor = (error: ApolloError) => {
    if (error.networkError) {
        console.log(error.networkError, ' --- networkError');
    }
    const firstGQLError = first(error.graphQLErrors);
    const code = firstGQLError?.extensions?.code;
    if (code) {
        return getEnumValueByKey(ERRORS, code as string, ERRORS.DEFAULT);
    }
    return ERRORS.DEFAULT;
};
