import { useMemo } from 'react';
import styled from 'styled-components';

import garbageIconSrc from '../../../assets/images/garbage.svg';

import { Button, DateRangePicker, Select, TextField } from '../../shared/components';
import { useTaskStatusesQuery } from 'graphql/api.types';
import { useTasksFiltersContext } from '../contexts/filters';
import { useAppState } from 'AppState';
import { MarginWrapper } from 'modules/shared/components/wrappers/grid/MarginWrapper';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 120%;
    color: #292527;
`;

const InputWrapper = styled.div`
    width: 238px;
    background-color: #fff;
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
`;

const GarbageIcon = styled.img.attrs({ src: garbageIconSrc })`
    width: 14px;
    cursor: pointer;
`;

interface TasksFiltersProps {
    onModalChange: (value: boolean) => void;
}

export const TasksFilters = ({ onModalChange }: TasksFiltersProps) => {
    const taskStatusesQuery = useTaskStatusesQuery();
    const { search, period, status, manager, onChange, reset } = useTasksFiltersContext();
    const {
        user,
        permissions: { isDirector }
    } = useAppState();

    const staff = useMemo(
        () =>
            user?.staff?.map(s => ({
                title: s.login,
                value: s.id
            })) || [],
        [user]
    );

    const taskStatusesItems = useMemo<{ title: string; value: string }[]>(() => {
        if (taskStatusesQuery.data)
            return taskStatusesQuery.data.taskStatuses.map(el => ({
                title: el.NAME,
                value: el.id
            }));
        return [];
    }, [taskStatusesQuery]);

    const onReportingClick = () => onModalChange(true);

    const renderManagerFilter = () => {
        if (staff.length > 0) {
            return (
                <InputWrapper>
                    <Select
                        placeholder="Все менеджеры"
                        items={staff}
                        selectedItem={manager}
                        onChange={value => onChange({ name: 'manager', value })}
                        withEmptyValue={true}
                    />
                </InputWrapper>
            );
        }
    };

    return (
        <Container>
            <Title>Задачи</Title>
            <FiltersContainer>
                {isDirector && (
                    <MarginWrapper marginRight="20px">
                        <Button height="40px" borderRadius="20px" onClick={onReportingClick} theme="secondary">
                            Отчетность
                        </Button>
                    </MarginWrapper>
                )}
                <InputWrapper className="tooltip-container">
                    <span className="tooltip-text">
                        Номер задачи, описание ТБ, имя клиента или модель, номер, VIN автомобиля
                    </span>
                    <TextField
                        value={search}
                        onChange={e => onChange({ name: 'search', value: e.target.value })}
                        placeholder="Поиск"
                    />
                </InputWrapper>
                <InputWrapper>
                    <DateRangePicker value={period} onChange={value => onChange({ name: 'period', value })} />
                </InputWrapper>
                <InputWrapper>
                    <Select
                        placeholder="Все статусы"
                        items={taskStatusesItems}
                        selectedItem={status}
                        onChange={value => onChange({ name: 'status', value })}
                        withEmptyValue={false}
                    />
                </InputWrapper>
                {renderManagerFilter()}
                <GarbageIcon onClick={reset} />
            </FiltersContainer>
        </Container>
    );
};
