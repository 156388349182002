import AsyncSelect from 'react-select/async';
import { useDebounceFn } from 'modules/shared/hooks/useDebounceFn';
import { ComboboxLoadFn, ComboboxOption } from 'modules/shared/components/inputs/Combobox/types';
import { DropdownWrapper, StyleWrapper } from 'modules/shared/components/inputs/Combobox/styles';
import { Placeholder, WithPortal } from 'types/data';
import { SelectPolygon } from 'modules/shared/components/Select';

export const DropdownIndicator = () => (
    <DropdownWrapper>
        <SelectPolygon />
    </DropdownWrapper>
);

// const MenuPortal = ({ children, appendTo, getStyles, controlElement, menuPosition: position, menuPlacement }: any) => {
//     const isFixed = position === 'fixed';
//
//     if (!appendTo || !controlElement) {
//         return null;
//     }
//
//     const scrollDistance = isFixed ? 0 : window.scrollY;
//     const rect = controlElement.getBoundingClientRect();
//     const offset = rect[menuPlacement] + scrollDistance;
//     const state = { offset, position, rect };
//     const styles = getStyles('menuPortal', state);
//
//     return (
//         <Portal node={document && appendTo}>
//             <div style={{ ...styles, zIndex: 10 }}>{children}</div>
//         </Portal>
//     );
// };

interface ComboboxProps extends Placeholder, WithPortal {
    loadFn: ComboboxLoadFn;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (newValue: any) => void;
    debounceTimer?: number;
    defaultOptions?: ComboboxOption[];
    value?: ComboboxOption | null;
}

export const Combobox = ({
    loadFn,
    onChange,
    debounceTimer = 500,
    placeholder,
    defaultOptions,
    value,
    withPortal
}: ComboboxProps) => {
    const loadOptions = useDebounceFn(loadFn, debounceTimer);

    return (
        <StyleWrapper>
            <AsyncSelect
                components={{ DropdownIndicator }}
                value={value}
                className="combobox"
                loadingMessage={() => <span>Загрузка...</span>}
                noOptionsMessage={() => <span>Не найдено</span>}
                classNamePrefix="cb"
                placeholder={placeholder}
                isClearable
                loadOptions={loadOptions}
                onChange={onChange}
                defaultOptions={defaultOptions}
                menuPortalTarget={withPortal ? (document.querySelector('#portalRoot') as HTMLElement) : undefined}
            />
        </StyleWrapper>
    );
};
