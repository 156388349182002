import Calendar from 'react-calendar';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import './calendar.css';
import selectPolygonSrc from '../../../../assets/images/selectPolygon.svg';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { getDatesRangeInIsoWeekByDate } from '../../../calendar/utils';

const SelectPolygon = styled.img.attrs({ src: selectPolygonSrc })`
    width: 12px;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 12px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #292527;
    cursor: pointer;
    border-radius: inherit;
`;

const Placeholder = styled.div`
    color: #93949f;
`;

const CalendarContainer = styled.div`
    width: 328px;
    height: 447px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

const CalendarButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const CalendarButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const CancelButton = styled(Button)`
    margin-right: 6px;
`;

export type DateType = null | Date;

type DatePickerProps = {
    value: DateType | Array<Date>;
    placeholder: string;
    maxDate?: Date;
    minDate?: Date;
    onChange: (value: DateType) => void;
    formattedValue?: string;
    selectByWeek?: boolean;
    onReset?: () => void;
    resetIsVisible?: boolean;
};

export const DatePicker = ({
    onReset,
    onChange,
    value,
    formattedValue,
    placeholder,
    maxDate,
    minDate,
    selectByWeek
}: DatePickerProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [tempValue, setTempValue] = useState<DateType | Array<Date>>(null);

    const onChangeTempValue = (v: DateType) => {
        if (selectByWeek && v) {
            return setTempValue(getDatesRangeInIsoWeekByDate(v));
        }
        setTempValue(v);
    };

    const renderTitle = () => {
        if (value) {
            if (formattedValue) {
                return <div>{formattedValue}</div>;
            }
            if (Array.isArray(value)) {
                return null;
            }
            const defaultFormattedValue = moment(value).format('DD.MM.yyyy');
            return <div>{defaultFormattedValue}</div>;
        }
        return <Placeholder>{placeholder}</Placeholder>;
    };

    const renderCalendar = () => {
        const onCancel = () => {
            setTempValue(null);
            setIsOpen(false);
        };

        const onSubmit = () => {
            if (Array.isArray(tempValue)) {
                onChange(tempValue[0]);
            } else {
                onChange(tempValue);
            }

            setTempValue(null);
            setIsOpen(false);
        };

        const onResetCb = () => {
            onReset && onReset();
            setIsOpen(false);
        };

        const calendarValue = tempValue || value;
        return (
            <Modal isOpen={isOpen}>
                <CalendarContainer>
                    <Calendar
                        selectRange={false}
                        value={calendarValue}
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={onChangeTempValue}
                    />
                    <CalendarButtonGroup>
                        <CancelButton theme="secondary" onClick={onResetCb}>
                            Сегодня
                        </CancelButton>
                        <CalendarButtonsContainer>
                            <CancelButton theme="secondary" onClick={onCancel}>
                                Отмена
                            </CancelButton>
                            <Button onClick={onSubmit}>Готово</Button>
                        </CalendarButtonsContainer>
                    </CalendarButtonGroup>
                </CalendarContainer>
            </Modal>
        );
    };

    return (
        <>
            <Container onClick={() => setIsOpen(true)}>
                {renderTitle()}
                <SelectPolygon />
            </Container>
            {renderCalendar()}
        </>
    );
};
