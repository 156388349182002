import React from 'react';
import { GuestRouter } from './GuestRouter';
import { UserRouter } from './UserRouter';
import { Header } from '../modules/shared/components';
import { BrowserRouter } from 'react-router-dom';

export const Router = () => {
    const token = localStorage.getItem('token');
    const renderSwitch = () => {
        if (!token) return <GuestRouter />;
        return (
            <>
                <Header />
                <div className="main-wrapper">
                    <UserRouter />
                </div>
            </>
        );
    };

    return <BrowserRouter>{renderSwitch()}</BrowserRouter>;
};
