import { Toaster } from 'react-hot-toast';

import { Router } from 'router';
import { CalendarFiltersContext } from 'modules/calendar/contexts/CalendarFiltersContext';
import { TasksFiltersContext } from 'modules/tasks/contexts/filters';
import { ClientsFiltersContext } from 'modules/clients/contexts/filters';
import { GlobalStyle } from 'constants/styles/global';

const App = () => {
    return (
        <TasksFiltersContext>
            <ClientsFiltersContext>
                <CalendarFiltersContext>
                    <GlobalStyle />
                    <Router />
                </CalendarFiltersContext>
                <Toaster />
            </ClientsFiltersContext>
        </TasksFiltersContext>
    );
};

export default App;
