import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ClientQueryResponse } from './index';
import { Title } from './Title';
import { CarCard } from './CarCard';
import { DropdownItem, Select } from '../../shared/components/Select';
import { useAppState } from 'AppState';
import { useUpdateCarManagerMutation } from 'graphql/api.types';
import { useFormik } from 'formik';
import { ButtonsGroup, CentredRow } from 'modules/shared/components/FormRows';
import { ModalTitle } from 'modules/shared/components/modals/styles';
import { Button, Modal } from 'modules/shared/components';

const Container = styled.div`
    width: 100%;
`;

const InputWrapper = styled.div`
    width: 312px;
    background-color: #fff;
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;

type MaxHeightProps = {
    maxHeight?: number;
    maxWidth?: number;
};

const ModalContainer = styled.div<MaxHeightProps>`
    padding: 20px;
    width: 100%;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '510px')};
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px;` : '')};
    background-color: #ffffff;
`;

const TextFieldContainer = styled.div`
    border: 1px solid #c5c6cb;
    box-sizing: border-box;
    border-radius: 20px;
    width: 230px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const Error = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #ec2830;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
`;

const Comment = styled.textarea`
    border: 1px solid #eaebee;
    width: 400px;
    height: 80px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    resize: none;
    padding: 10px 14px;

    &::placeholder {
        color: #93949f;
    }
`;

type CarsProps = { client: ClientQueryResponse; onUpdate: () => void };

export const Cars = ({ client, onUpdate }: CarsProps) => {
    const cars = useMemo(() => client?.cars || [], [client]);
    const carItems = useMemo<DropdownItem[]>(() => {
        return (cars || []).map(({ id, CAR_MODEL }) => ({ title: CAR_MODEL, value: id }));
    }, [cars]);
    const [selectedCar, setCar] = useState<DropdownItem | null>(carItems[0]);

    const car = useMemo(() => {
        return cars?.find(c => c.id === selectedCar?.value) || null;
    }, [cars, selectedCar?.value]);

    const { user } = useAppState();

    const [managerModalOpen, setManagerModalOpen] = useState(false);

    const isManager = useMemo(() => {
        if (user) {
            return (user?.staff || []).length < 1;
        }
        return true;
    }, [user]);

    const [mutation] = useUpdateCarManagerMutation();
    const formInitialValues = {
        comment: car?.commentUserId || '',
        manager: { title: car?.user?.login || '', value: car?.user?.id || '' }
    };
    const formData = useFormik({
        initialValues: formInitialValues,
        validate: values => {
            const errors = {};
            if (!values.comment && car?.user?.id !== values.manager.value) {
                //@ts-ignore
                errors.comment = 'Укажите комментарий';
            }
            return errors;
        },
        onSubmit: values => {
            mutation({
                variables: {
                    carId: car?.id || '',
                    managerId: values.manager.value,
                    comment: values.comment
                }
            }).then(() => onUpdate());
            setManagerModalOpen(false);
        },
        onReset: () => {
            setManagerModalOpen(false);
        }
    });

    const managersSelectValues = useMemo(() => {
        if (user) {
            return (user?.staff || [])
                .filter(s => s.id !== client?.manager.id)
                .map(s => ({ title: s.login, value: s.id }));
        }
        return [];
    }, [user, client?.manager.id]);

    const onChangeManager = (v: DropdownItem | null) => {
        formData.setFieldValue('manager', v);
    };

    useEffect(() => {
        if (managerModalOpen) {
            formData.setValues(formInitialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerModalOpen]);

    return client?.cars ? (
        <Container>
            <Title>Автомобили ({carItems.length})</Title>
            <InputWrapper>
                <Select
                    placeholder="Все автомобили"
                    items={carItems}
                    selectedItem={selectedCar}
                    onChange={value => setCar(value)}
                    withEmptyValue={false}
                />
            </InputWrapper>
            {car && <CarCard isManager={isManager} onOpenManagerModal={setManagerModalOpen} car={car} />}
            <Modal isOpen={managerModalOpen}>
                <form onReset={formData.handleReset} onSubmit={formData.handleSubmit}>
                    <ModalContainer>
                        <CentredRow>
                            <ModalTitle>Назначить персонального менеджера</ModalTitle>
                        </CentredRow>
                        <TextFieldContainer>
                            <Select
                                items={managersSelectValues}
                                selectedItem={formData.values.manager || null}
                                onChange={onChangeManager}
                                placeholder={'Выберите менеджера'}
                                withEmptyValue={false}
                            />
                        </TextFieldContainer>
                        <Comment
                            name="comment"
                            placeholder="Комментарий"
                            value={formData.values.comment}
                            onChange={formData.handleChange}
                        />
                        {formData.errors.comment && <Error>{formData.errors.comment}</Error>}
                        <ButtonsGroup>
                            <Button theme="secondary" type="reset">
                                отмена
                            </Button>
                            <Button type="submit">Сохранить</Button>
                        </ButtonsGroup>
                    </ModalContainer>
                </form>
            </Modal>
        </Container>
    ) : null;
};
