import { useEffect } from 'react';

const body = document.body;

export const useNonScrolledBackground = (visible?: boolean, withoutCheck?: boolean) =>
    useEffect(() => {
        if (!withoutCheck) {
            visible ? (body.style.overflow = 'hidden') : (body.style.overflow = 'auto');
        }
    }, [visible, withoutCheck]);
